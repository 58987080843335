import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

// Icons
import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const CardContainer = styled("div")(({ status }) => ({
    borderRadius: "8px",
    backgroundColor: (() => {
        switch (status) {
            case "available":
                return "#e0e0e0";
            case "busy":
                return "#07bc0c";
            case "dirty":
                return "#757575";
            case "cleaning in progress":
                return "#f1c40f";
            case "in maintains":
                return "#0dcaf0";
            case "Booked":
                return '#A020F0'
            default:
                return "#ffffff";
        }
    })(),
    textAlign: "center",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
    position: "relative",
    minHeight: "190px"
}));

const Card = ({ data, transmissionedHandleClick, isFlipped, onFlip }) => {
    const handleClick = (event, value) => {
        event.stopPropagation();
        transmissionedHandleClick(event, value);
    };

    return (
        <>
            <CardContainer style={{ ...styles.cardInner, ...styles.card, ...(isFlipped ? styles.flipped : {}) }} status={data.status_name} onClick={onFlip}>
                <div style={{ ...styles.general, ...styles.cardFront }}>
                    <div className="options" style={{ display: "flex", justifyContent: "end", alignItems: "end", flex: "1 1 200px", position: "absolute", right: 10, top: 10 }}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, data)}
                        >
                            <MoreVertIcon style={{ color: "#fff" }} />
                        </IconButton>
                    </div>
                    <p>{data?.number}</p>
                    <p>{data?.category_name}</p>
                </div>

                <div style={{ ...styles.general, ...styles.cardBack }}>
                    <div style={styles.iconsWrapper}>
                        <GroupIcon style={{ color: "#000" }} />
                        <div>
                            <span>{data.reservations.length}</span>
                            <span>/</span>
                            <span>{data.number_of_people}</span>
                        </div>
                    </div>
                    <div style={styles.iconsWrapper}>
                        <LocalOfferIcon style={{ color: "#000" }} />
                        <div>
                            <span>{data.price}</span>
                        </div>
                    </div>
                    <div style={styles.iconsWrapper}>
                        <InfoIcon style={{ color: "#000" }} />
                        <div>
                            <span>{data.status_name || data.status}</span>
                        </div>
                    </div>
                </div>
            </CardContainer>
        </>
    );
};

const styles = {
    card: {
        perspective: "1000px",
        cursor: "pointer",
        userSelect: "none"
    },

    cardInner: {
        position: "relative",
        width: "100%",
        height: "200px",
        transition: "transform 0.6s",
        transformStyle: "preserve-3d"
    },

    flipped: {
        transform: "rotateY(180deg)"
    },

    general: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backfaceVisibility: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px"
    },

    cardFront: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },

    cardBack: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
        transform: "rotateY(180deg)",
        justifyContent: "space-around"
    },

    iconsWrapper: {
        display: "flex",
        gap: "2px",
        alignItems: "center",
        flexDirection: "column"
    }
}

export default Card;
