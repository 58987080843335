import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { t } from "i18next";
import { baseregex, phoneregex } from "./AddContact.jsx";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ getTableData, data, close }) {
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validate = Yup.object({
        contact_name: Yup.string()
            .required(t("Field is required"))
            .matches(baseregex, { message: t("Invalid value") }),
        email: Yup.string().optional().nullable().email(t("Invalid value")),
        phone_number: Yup.string().when("email", (email, schema) => {
            if (!email[0])
                return schema
                    .required(t("Must contain at least one of the following: phone number, email"))
                    .matches(phoneregex, { message: t("Invalid value") });
            else return schema.nullable().optional();
        }),
    });

    const onUpdate = async (values) => {
        setLoading(true);
        const data = { id: values.id, contact_name: values.contact_name, phone_number: values.phone_number || null, email: values.email || null };
        await axios
            .put(`${process.env.REACT_APP_API_URL}/hdp-gateway/contact`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                toast.success(t("Updated successfully"));
                handleClose();
                close();
                getTableData();
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t(err?.response?.data?.errors[0]?.message || "An error occurred"));
            });
    };

    return (
        <div>
            <Typography onClick={handleOpen} title="Click">
                {t("Update")}
            </Typography>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="modal_res">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Update contact info")}
                        </Typography>
                        <Formik initialValues={data} enableReinitialize validationSchema={validate} onSubmit={onUpdate}>
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Field fullWidth name="contact_name" size="small" component={TextField} label={t("Contact name")} type="text" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field fullWidth name="phone_number" size="small" component={TextField} label={t("Phone number")} type="text" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field fullWidth name="email" size="small" component={TextField} label={t("Email")} type="text" />
                                            </Grid>
                                        </Grid>
                                    </>
                                    {loading && <div className="overlay" />}
                                    <div className="right_left">
                                        <Button variant="contained" color="error" sx={{ mt: 3, mb: 2 }} onClick={handleClose}>
                                            {t("Close")}
                                        </Button>
                                        <>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {t("Submit")}
                                                </LoadingButton>
                                            ) : (
                                                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {t("Submit")}
                                                </Button>
                                            )}
                                        </>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
