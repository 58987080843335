import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import CheckInPage from "../../pages/CheckInPage";
import CheckOutPage from "../../pages/CheckOutPage";
import AdminRoomsPage from "../../pages/AdminRoomsPage";
import NotFound from "../../components/NotFound";
import AdminCategoriesPage from "../../pages/AdminCategoriesPage";
import BookingPage from "../../pages/BookingPage";
import RoomsPage from "../../pages/RoomsPage";
import HomePage from "../../pages/HomePage";
import AdminUsersPage from "../../pages/AdminUsersPage";
import AdminAssignmentPage from "../../pages/AdminAssignmentPage";
import HousekeepingPage from "../../pages/HousekeepingPdfPage";
import SupervisorAssignmentPage from "../../pages/SupervisorAssignmentPage";
import Shaxmatka from "../../pages/Shaxmatka";
import HousekeepersRoomsPage from "../../pages/HouekeepingRoomsPage";
import ProfilePage from "../../pages/ProfilePage";
import ReportsPage from '../../pages/ReportsPage'
import DaliyReportsPage from '../../pages/DaliyReportsPage'
import CheckOut from "../../pages/CheckOutPage2";
import CheckoutPDF from "../CheckOut/PDF";
import CategoryPictures from "../Admin-categories/Pictures/Pictures";
import HotelImages from "../../pages/HotelImagePage";
import HotelAmenities from "../Hotel-amenities";
import HotelLogo from "../Admin-logo/Index";
import CategoryAmenities from "../Admin-categories/Amenities/Index";
import HotelInfoPage from "../../pages/HotelInfoPage";
import HotelPolicyPage from "../../pages/HotelPolicyPage";
import AdminContactsPage from "../../pages/AdminContactsPage";
import BookingReportsPage from "../../pages/BookingReportsPage";
import SupervisorAssignmentPagev2 from "../../pages/SupervisorAssignmentPagev2";
import DisCount from "../Admin-categories/DisCount";
import CustomTabPanel from '../Admin-users/Tabs'


const { Content } = Layout;

const Main = (props) => {
  const { handleOpenMainModal, isPartner } = props;

  return (
    <Content id="head_content" style={{ maxWidth: "100%", maxHeight: "87.5vh", overflow: "scroll" }}>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" exact element={<HomePage />} />
        <Route path="/check-in" exact element={<CheckInPage />} />
        <Route path="/master-book" exact element={<Shaxmatka
          handleOpenMainModal={handleOpenMainModal}
          isPartner={isPartner}
        />} />
        <Route path="/master-book/rooms" exact element={<Shaxmatka
          handleOpenMainModal={handleOpenMainModal}
          isPartner={isPartner}
        />} />
        <Route path="/check-out" exact element={<CheckOutPage />} />
        <Route path="/check-out/:id" exact element={<CheckOut />} />
        <Route path="/check-out/:id/pdf" exact element={<CheckoutPDF />} />
        <Route path="/rooms" exact element={<RoomsPage />} />
        <Route path="/categories" exact element={<AdminCategoriesPage />} />
        <Route path="/categories/:id" exact element={<CategoryPictures />} />
        <Route path="/categories/:id/amenities" exact element={<CategoryAmenities />} />
        <Route path="/discount" exact element={<DisCount />} />
        <Route path="/hotel-images" exact element={<HotelImages />} />
        <Route path="/hotel-amenities" exact element={<HotelAmenities />} />
        <Route path="/hotel-info" exact element={<HotelInfoPage />} />
        <Route path="/hotel-policy" exact element={<HotelPolicyPage />} />
        <Route path="/hotel-logo" exact element={<HotelLogo />} />
        <Route path="/booking" exact element={<BookingPage />} />
        <Route path="/admin-rooms" exact element={<AdminRoomsPage />} />
        <Route path="/admin-users" element={<AdminUsersPage
          handleOpenMainModal={handleOpenMainModal}
          isPartner={isPartner}
        />} />
        <Route path="/admin-users/:user_id/tab" element={<CustomTabPanel/>}/>
        <Route path="/contacts" element={<AdminContactsPage
          handleOpenMainModal={handleOpenMainModal}
          isPartner={isPartner}
        />} />
        <Route path="/role-assignment" element={<AdminAssignmentPage />} />
        <Route
          path="/housekeeping-assignment"
          element={<SupervisorAssignmentPage />}
        />
        <Route
          path="/housekeeping-assignmentv2"
          element={<SupervisorAssignmentPagev2 />}
        />
        <Route path="/housekeeping-pdf" exact element={<HousekeepingPage />} />
        <Route path="/housekeepers-rooms" element={<HousekeepersRoomsPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/monthly-report" element={<ReportsPage />} />
        <Route path="/daily-report" element={<DaliyReportsPage />} />
        <Route path="/booking-report" element={<BookingReportsPage />} />
      </Routes>
    </Content>
  );
};

export default Main;
