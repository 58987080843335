import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import axios from "axios";
import { Empty } from "antd";
import ShaxmatkaPult from "./Settings/Pult";
import ShaxmatkaSettings from "./Settings/Settings";
import ShaxmatkaGuests from "./Guests/Guests";
import ShaxmatkaItem from "./TableItem/Item";
import Overlay from "./Overlay";
import { useTranslation } from "react-i18next";
import ShaxmatkaBooking from "./Booking/Booking";
import ShaxmatkaCheckOut from "./CheckOut/CheckOut";
import ShaxmatkaCheckIn from "./CheckIn/CheckIn";
  
import ShaxmatkaItemShared from "./TableItem/ItemShared";
import { dateDDMMYYYY, dateLocalISO, dateMonth, dateWeekDay } from "../../hooks/dateFormatter";
import ShaxmatkaUpdateBooking from "./Booking/UpdateBooking";
import ShaxmatkaCancelBooking from "./Booking/Cancelbooking";
import ShaxmatkaUpdateCheckIn from "./CheckIn/UpdateCheckIn";
import ShaxmatkaFilters from "./Filters/Filters";
import ShaxmatkaSorts from "./Sorts/Sort";
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
// Icons
import SettingsIcon from '@mui/icons-material/Settings';
import ShaxmatkaTransfer from "./CheckIn/Transfer";
import { Button } from "@mui/material";
import HoverCard from "./HoverCard";
const tableWidthTypes = ["weekly", "monthly", "10 days", "14 days", "20 days"];



const Shaxmatka = () => {
    const { t } = useTranslation();
    const [today, setToday] = useState(new Date());
    const [days, setDays] = useState([]);
    const [numberOfDays, setNumberOfDays] = useState(14);
    const [tableWidth, setTableWidth] = useState("14 days");
    const [categories, setCategories] = useState([]);
    const [bookingTypes, setBookingTypes] = useState([]);

    // Filter
    const [filter, setFilterProp] = useState({
        number: null,
        status: "",
        category: "",
        priceRange: [0, Infinity],
        minValue: 0,
        maxValue: Infinity,
    });

    // Sort
    const [sort, setSortProp] = useState({
        orderBy: "sortNumber",
        sortBy: "asc"
    });

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [fulldata, setFullData] = useState([]);
    const [policy, setpolicy] = useState({});
    const [checkInModal, setCheckInModal] = useState(false);
    const [checkInUpdateModal, setCheckInUpdateModal] = useState(false);
    const [transferModal, settransferModal] = useState(false);
    const [bookingModal, setBookingModal] = useState(false);
    const [bookingUpdateModal, setBookingUpdateModal] = useState(false);
    const [bookingCancelModal, setBookingCancelModal] = useState(false);
    const [checkOutModal, setCheckOutModal] = useState(false);
    const [settingsModal, setSettingsModal] = useState(false);
    const [filtersModal, setFilterssModal] = useState(false);
    const [sortsModal, setSortsModal] = useState(false);
    const [guestsModal, setGuestsModal] = useState(false);
    const [guests, setGuests] = useState([]);
    const [bookingData, setBookingData] = useState({});
    const [bookingUpdateData, setBookingUpdateData] = useState({});
    const [bookingCancelData, setBookingCancelData] = useState({});
    const [checkInData, setCheckInData] = useState({});
    const [checkInUpdateData, setCheckInUpdateData] = useState({});
    const [transferdata, settransferdata] = useState({});
    const [checkOutData, setCheckOutData] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);

    const getCategories = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/room/category/list`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setCategories(res.data.data))
            .catch((err) => console.log(err));
    };

    const getBookingTypes = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/booking/types`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setBookingTypes(res.data.data))
            .catch((err) => console.log(err));
    };

    function getFilter() {
        let filteredRooms = fulldata
            .filter((e) => {
                if (filter.category !== "") {
                    return e?.name === filter.category
                }
                return true;
            }).map((e) => e.rooms)
            .flat()
            .filter((room) => {
                if (filter.number) {
                    return room.number.toLowerCase().includes(filter.number.toLowerCase());
                }
                return true;
            })
            .filter((room) => {
                return room.price >= (filter.priceRange[0] || 0) && room.price <= (filter?.priceRange[1] || Infinity)
            })
            .filter((room) => {
                if (filter.status) {
                    return room?.status === filter.status
                }
                return true;
            })

        if (sort.orderBy === "sortNumber") {
            filteredRooms = filteredRooms.sort((a, b) => {
                if (sort.sortBy === "asc") {
                    return a.number - b.number;
                } else {
                    return b.number - a.number;
                }
            })
        } else if (sort.orderBy === "sortPrice") {
            filteredRooms = filteredRooms.sort((a, b) => {
                if (sort.sortBy === "asc") {
                    return a.price - b.price;
                } else {
                    return b.price - a.price;
                }
            });
        }

        setData(filteredRooms);
    }

    const getData = async () => {
        if (days.length > 0) {
            const query = `?start_date=${dateLocalISO(days[0])}&end_date=${dateLocalISO(days[days.length - 1])}`;
            setLoading(true);
            await axios
                .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/booking/v2${query}`, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    res.data.data.roomCategories.forEach((e) => e.rooms.forEach((i) => (i.category = { name: e.name, id: e.id })));

                    const rooms = res.data.data.roomCategories.map((e) => e.rooms).flat();

                    setFilterProp((prev) => {
                        return { ...prev, minValue: Math.min(...rooms.map((room) => room.price)), maxValue: Math.max(...rooms.map((room) => room.price)) }
                    });

                    setFullData(res.data.data.roomCategories);
                })
                .catch((err) => console.log(err));
            await axios
                .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/hotel/policy`, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    setpolicy(res.data.data.data)
                })
                .catch((err) => console.log(err));

            setLoading(false);
        }
    };

    useEffect(() => getFilter(), [filter]);

    useEffect(() => {
        getCategories();
        getBookingTypes();
    }, []);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const rooms = fulldata.map((e) => e.rooms).flat();
        setFilterProp((prev) => {
            return { ...prev, minValue: Math.min(...rooms.map((room) => room.price)), maxValue: Math.max(...rooms.map((room) => room.price)) }
        });
    }, [fulldata]);

    const nextDays = () => {
        const newDays = [];
        let newToday;
        if (tableWidth === "monthly") {
            newToday = new Date(new Date(today).getFullYear(), new Date(today).getMonth() + 1, 1);
            const newNumberOfDays = daysInMonth(newToday);
            newDays.push(newToday);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(newToday, i));
            setNumberOfDays(newNumberOfDays);
        } else {
            newToday = daysLater(today, numberOfDays);
            for (let i = 0; i < numberOfDays; i++) {
                newDays.push(daysLater(newToday, i));
            }
        }
        setToday(newToday);
        setDays(newDays);
    };

    const prevDays = () => {
        const newDays = [];
        let newToday;
        if (tableWidth === "monthly") {
            newToday = new Date(new Date(today).getFullYear(), new Date(today).getMonth() - 1, 1);
            const newNumberOfDays = daysInMonth(newToday);
            newDays.push(newToday);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(newToday, i));
            setNumberOfDays(newNumberOfDays);
        } else {
            newToday = daysAgo(today, numberOfDays);
            for (let i = 0; i < numberOfDays; i++) {
                newDays.push(daysLater(newToday, i));
            }
        }
        setToday(newToday);
        setDays(newDays);
    };

    useEffect(() => {
        if (days.length > 0) getData();
    }, [days]);

    const onBooking = (params) => {
        setBookingData(params);
        setBookingModal(true);
    };

    const onBookingUpdate = (params) => {
        setBookingUpdateData(params);
        setBookingUpdateModal(true);
    };
    const onBookingCancel = (params) => {
        setBookingCancelData(params);
        setBookingCancelModal(true);
    };

    const onCheckIn = (params) => {
        setCheckInData(params);
        setCheckInModal(true);
    };

    const onCheckInUpdate = (params) => {
        setCheckInUpdateData(params);
        setCheckInUpdateModal(true);
    };
    const onTransferotherRoom = (params) => {
        settransferdata(params);
        settransferModal(true);
    };

    const onTransfer = (booking, room) => {
        setCheckInData({ ...booking, room });
        setCheckInModal(true);
        // setTransferData({ ...booking, room });
        // setTransferModal(true);
    };

    const onCheckOut = (currentItems) => {
        const x = [];
        for (let i = 0; i < currentItems.length; i++) {
            if (currentItems[i].bookingStatus === 1) {
                const find = x.find((e) => e[0].booking_id === currentItems[i].booking_id);
                if (find) find.push(currentItems[i]);
                else x.push([currentItems[i]]);
            }
        }
        setCheckOutData(x);
        setCheckOutModal(true);
    };

    useEffect(() => {
        setSettingsModal(false);
        const newDays = [];
        let newNumberOfDays;
        let newToday;
        if (tableWidth === "10 days") {
            newToday = today;
            newNumberOfDays = 10;
            newDays.push(today);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(today, i));
        }
        if (tableWidth === "14 days") {
            newToday = today;
            newNumberOfDays = 14;
            newDays.push(today);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(today, i));
        }
        if (tableWidth === "20 days") {
            newToday = today;
            newNumberOfDays = 20;
            newDays.push(today);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(today, i));
        }
        if (tableWidth === "monthly") {
            newNumberOfDays = daysInMonth(today);
            const current_date = new Date(today).getDate();
            newToday = daysAgo(today, current_date - 1);
            for (let i = 1; i < current_date; i++) newDays.push(daysAgo(today, current_date - i));
            newDays.push(today);
            for (let i = 1; i <= newNumberOfDays - current_date; i++) newDays.push(daysLater(today, i));
        }
        if (tableWidth === "weekly") {
            newNumberOfDays = 7;
            const current_date = new Date(today).getDay();
            newToday = daysAgo(today, current_date - 1);
            for (let i = 1; i < current_date; i++) newDays.push(daysAgo(today, current_date - i));
            newDays.push(today);
            for (let i = 1; i <= newNumberOfDays - current_date; i++) newDays.push(daysLater(today, i));
        }
        setToday(newToday);
        setNumberOfDays(newNumberOfDays);
        setDays(newDays);
    }, [tableWidth]);

    // hover
    const [hoverCard, setHoverCard] = useState({ x: 0, y: 0 });
    const [hoverRoomId, setHoverRoomId] = useState({ visible: false, x: 0, y: 0 });
    let hoverTimeout;

    const handleMouseEnter = (event, id) => {
        hoverTimeout = setTimeout(() => {
            const rect = event.target.getBoundingClientRect();
            setHoverRoomId(id);
            setHoverCard({
                x: rect.right - 210,
                y: rect.bottom - 330,
            });
        }, 150);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout);
        setHoverRoomId(null);
        setHoverCard({ x: 0, y: 0 });
    };

    return (
        <div className={styles.fullPage} style={{ "--times": numberOfDays }}>
            <div className={styles.noDataContainer}>{t("This page is not intended for such a screen")}</div>
            <div className={styles.dataContainer}>
                {loading && <Overlay />}
                {fulldata.length > 0 && (
                    <>
                        <ShaxmatkaCheckIn show={checkInModal} setShow={setCheckInModal} checkInData={checkInData} getData={getData} categories={categories} />
                        <ShaxmatkaUpdateCheckIn
                            show={checkInUpdateModal}
                            setShow={setCheckInUpdateModal}
                            getData={getData}
                            checkInData={checkInUpdateData}
                            categories={categories}
                        />
                        <ShaxmatkaTransfer
                            show={transferModal}
                            setShow={settransferModal}
                            getData={getData}
                            transferdata={transferdata}
                            categories={categories}
                        />
                        <ShaxmatkaBooking
                            show={bookingModal}
                            setShow={setBookingModal}
                            bookingTypes={bookingTypes}
                            getData={getData}
                            bookingData={bookingData}
                        />
                        <ShaxmatkaUpdateBooking
                            show={bookingUpdateModal}
                            setShow={setBookingUpdateModal}
                            bookingTypes={bookingTypes}
                            getData={getData}
                            bookingData={bookingUpdateData}
                            categories={categories}
                        />
                        <ShaxmatkaCancelBooking
                            show={bookingCancelModal}
                            setShow={setBookingCancelModal}
                            bookingTypes={bookingTypes}
                            getData={getData}
                            bookingData={bookingCancelData}
                            categories={categories}
                        />
                        <ShaxmatkaCheckOut show={checkOutModal} setShow={setCheckOutModal} getData={getData} checkOutData={checkOutData} />
                        <ShaxmatkaSettings
                            show={settingsModal}
                            setShow={setSettingsModal}
                            setTableWidth={setTableWidth}
                            tableWidth={tableWidth}
                            tableWidthTypes={tableWidthTypes}
                        />
                        <ShaxmatkaFilters
                            show={filtersModal}
                            setShow={setFilterssModal}
                            setTableWidth={setTableWidth}
                            tableWidth={tableWidth}
                            tableWidthTypes={tableWidthTypes}
                            filterProp={filter}
                            categories={categories}
                            setFilterProp={setFilterProp}
                            getDataWithFilter={getData}
                        />
                        <ShaxmatkaSorts
                            show={sortsModal}
                            setShow={setSortsModal}
                            setTableWidth={setTableWidth}
                            tableWidth={tableWidth}
                            tableWidthTypes={tableWidthTypes}
                            sortProp={sort}
                            setSortProp={setSortProp}
                            getDataWithFilter={getData}
                        />
                        <ShaxmatkaGuests show={guestsModal} setShow={setGuestsModal} guests={guests} data={data} />
                        <div className={styles.filter} >
                            <div className={styles.categoryContainer}>
                                <div className={styles.headerBlock1}>
                                    <Button variant="contained" sx={{ minWidth: '36px !important', padding: "6px 0" }} onClick={() => setSettingsModal(true)}>
                                        <SettingsIcon />
                                    </Button>
                                    <Button variant="contained" sx={{ minWidth: '36px !important', padding: "6px 0" }} onClick={() => setFilterssModal(true)}>
                                        <TuneRoundedIcon />
                                    </Button>
                                    <Button variant="contained" sx={{ minWidth: '36px !important', padding: "6px 0" }} onClick={() => setSortsModal(true)}>
                                        <SortRoundedIcon />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.bigContainer}>
                            {data.length > 0 && <>
                                <div className={styles.bigContainerHead}>
                                    <div>{todayMonth(days, t)}</div>
                                </div>
                                {days.map((e) => (
                                    <div key={e} className={styles.dayContainer}>
                                        <div>
                                            {85 * numberOfDays + 400 < width
                                                ? dateDDMMYYYY(dateLocalISO(e))
                                                : 50 * numberOfDays + 400 < width
                                                    ? dateDDMMYYYY(dateLocalISO(e)).slice(0, 5)
                                                    : dateDDMMYYYY(dateLocalISO(e)).slice(0, 2)}
                                        </div>
                                        <div style={{ fontSize: "0.875em", color: "#66339995" }}>
                                            {85 * numberOfDays + 400 < width
                                                ? t(dateWeekDay(e))
                                                : 40 * numberOfDays + 400 < width
                                                    ? t(dateWeekDay(e).slice(0, 3))
                                                    : ""}
                                        </div>
                                    </div>
                                ))}
                            </>}
                            {data.map((room) => (
                                <React.Fragment>
                                    <div className={styles.room} key={room.id} style={{ borderLeftColor: colorRoomStatus(room.status) }}
                                        onMouseEnter={(e) => { handleMouseEnter(e, room.id); }}
                                        onMouseLeave={() => { handleMouseLeave(); }}
                                    >
                                        <div className={styles.roomNumber}>{room.number}</div>
                                        <div className={styles.roomCategory}>{room.category.name}</div>
                                        <HoverCard visible={hoverRoomId === room.id} x={hoverCard.x} y={hoverCard.y} data={room} />
                                    </div>
                                    {room.is_shared === false &&
                                        days.map((day) => (
                                            <ShaxmatkaItem
                                                data={room.reservations || []}
                                                day={day}
                                                setGuestsModal={setGuestsModal}
                                                today={today}
                                                setGuests={setGuests}
                                                setCheckInModal={setCheckInModal}
                                                onBooking={onBooking}
                                                onBookingUpdate={onBookingUpdate}
                                                onBookingCancel={onBookingCancel}
                                                onCheckIn={onCheckIn}
                                                onCheckInUpdate={onCheckInUpdate}
                                                onCheckOut={onCheckOut}
                                                onTransfer={onTransfer}
                                                onTransferotherRoom={onTransferotherRoom}
                                                policy={policy}
                                                days={days}
                                                room={{
                                                    id: room.id,
                                                    number: room.number,
                                                    number_of_people: room.number_of_people,
                                                    price: room.price,
                                                    status: room.status,
                                                    category: room.category,
                                                }}
                                            />
                                        ))}

                                    {room.is_shared === true &&
                                        days.map((day) => (
                                            <ShaxmatkaItemShared
                                                data={room.reservations || []}
                                                day={day}
                                                setGuestsModal={setGuestsModal}
                                                today={today}
                                                setGuests={setGuests}
                                                setCheckInModal={setCheckInModal}
                                                onBookingCancel={onBookingCancel}
                                                onBooking={onBooking}
                                                onBookingUpdate={onBookingUpdate}
                                                onCheckIn={onCheckIn}
                                                onCheckInUpdate={onCheckInUpdate}
                                                policy={policy}
                                                onCheckOut={onCheckOut}
                                                onTransferotherRoom={onTransferotherRoom}
                                                onTransfer={onTransfer}
                                                days={days}
                                                room={{
                                                    id: room.id,
                                                    number: room.number,
                                                    status: room.status,
                                                    number_of_people: room.number_of_people,
                                                    price: room.price,
                                                    category: room.category,
                                                }}
                                            />
                                        ))}
                                </React.Fragment>
                            ))}
                        </div>
                    </>
                )}
                {data.length > 0 && (
                    <div className={styles.pultContainer}>
                        <ShaxmatkaPult today={today} next={nextDays} prev={prevDays} />
                    </div>
                )}
                {(!data && data?.length === 0 && !loading) && <Empty description={t("No Data yet")} />}
            </div>
        </div>
    );
};

export default Shaxmatka;

function daysLater(today, difference) {
    return new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
}

function daysAgo(today, difference) {
    return new Date(today.getTime() - difference * 24 * 60 * 60 * 1000);
}

function todayMonth(days, t) {
    if (!days || days.length === 0) return "";
    return `${dateLocalISO(days[0]).slice(0, 4)}-${t(dateMonth(days[0]))}`;
}

function colorRoomStatus(status) {
    switch (status) {
        case "available":
            return "#cccccc";
        case "busy":
            return "#43a047";
        case "dirty":
            return "#757575";
        case "cleaning in progress":
            return "#fdd835";
        case "in maintains":
            return "#9ccc65";
        case "cleaning complete":
            return "#1e88e5";

        default:
            return "";
    }
}

function daysInMonth(date) {
    return new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0).getDate();
}
