import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import style from "./styles.module.css";
import { Field, Form, Formik } from "formik";
import { Button, Grid } from "@mui/material";
import { TextField } from "formik-material-ui";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

const ChangePassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    if (searchParams.get("ftu")) sessionStorage.setItem("ftu", searchParams.get("ftu"));
    const token = sessionStorage.getItem("ftu");

    const validate = Yup.object({
        login: Yup.string().required(t("Field is required")),
        old_password: Yup.string().required(t("Field is required")),
        new_password: Yup.string().required(t("Field is required")),
        confirm_password: Yup.string().required(t("Field is required")),
    });
    const emptyData = { login: "", old_password: "", new_password: "", confirm_password: "" };

    const [loading, setLoading] = useState(false);
    const [isViewOldPassword, setIsViewOldPassword] = useState(false);
    const [isViewNewPassword, setIsViewNewPassword] = useState(false);
    const [isViewConfirmPassword, setIsViewConfirmPassword] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            navigate("/ftu");
        });
    }, []);

    const checkPassword = (password, confirmPassword) => {
        if (password.length < 8) return t("Parol kamida 8ta belgidan iborat bo'lsin");
        if (!/[A-Z]+/.test(password)) return t("Parolda katta harf qatnashsin");
        if (!/[a-z]+/.test(password)) return t("Parolda kichik harf qatnashsin");
        if (!/[0-9]+/.test(password)) return t("Parolda raqam qatnashsin");
        if (!/[^A-Za-z0-9]+/.test(password)) return t("Parolda maxsus belgi qatnashsin. Masalan: !@#$%^&*+-=.");
        if (password && password !== confirmPassword) return t("Parolni takrorlash");
        return "";
    };

    const onSubmit = async (data) => {
        setLoading(true);
        await axios
            .post(`${process.env.REACT_APP_API_URL}/hdp-gateway/ftu/set`, data, { headers: { Authorization: token } })
            .then(() => {
                setLoading(false);
                toast.success(t("Password has been set"));
                navigate("/login");
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t(err?.response?.data?.errors[0]?.message || "An error occurred"));
            });
    };

    return (
        <div className={style.container}>
            <Formik initialValues={emptyData} validationSchema={validate} onSubmit={onSubmit}>
                {({ values }) => (
                    <div className={style.box}>
                        <h2 className={style.title}>{t("Create password")}</h2>
                        <div className={style.changePassword}>
                            <Form autoComplete="off" className="mt-4">
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Field fullWidth name="login" size="small" component={TextField} label={t("ID")} type="text" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field fullWidth name="old_password" size="small" component={TextField} label={t("Temporary password")} type="password" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field fullWidth name="new_password" size="small" component={TextField} label={t("New password")} type="password" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            fullWidth
                                            name="confirm_password"
                                            size="small"
                                            component={TextField}
                                            label={t("Confirm password")}
                                            type="password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={style.infoPasswordForPhone}>{checkPassword(values.new_password, values.confirm_password)}</div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {loading ? (
                                            <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success" className="w-100">
                                                {t("Submit")}
                                            </LoadingButton>
                                        ) : (
                                            <Button
                                                disabled={checkPassword(values.new_password, values.confirm_password) !== ""}
                                                type="submit"
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                color="success"
                                                className="w-100"
                                            >
                                                {t("Submit")}
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                                {loading && <div className="overlay" />}
                            </Form>
                        </div>
                        <div className={style.infoPassword}>
                            <div>
                                {values.new_password.length > 7 ? (
                                    <span className={style.check}>✓ {t("Parol kamida 8ta belgidan iborat bo'lsin")}</span>
                                ) : (
                                    <span className={style.dont}>✗ {t("Parol kamida 8ta belgidan iborat bo'lsin")}</span>
                                )}
                            </div>
                            <div>
                                {/[A-Z]+/.test(values.new_password) ? (
                                    <span className={style.check}>✓ {t("Parolda katta harf qatnashsin")}</span>
                                ) : (
                                    <span className={style.dont}>✗ {t("Parolda katta harf qatnashsin")}</span>
                                )}
                            </div>
                            <div>
                                {/[a-z]+/.test(values.new_password) ? (
                                    <span className={style.check}>✓ {t("Parolda kichik harf qatnashsin")}</span>
                                ) : (
                                    <span className={style.dont}>✗ {t("Parolda kichik harf qatnashsin")}</span>
                                )}
                            </div>
                            <div>
                                {/[0-9]+/.test(values.new_password) ? (
                                    <span className={style.check}>✓ {t("Parolda raqam qatnashsin")}</span>
                                ) : (
                                    <span className={style.dont}>✗ {t("Parolda raqam qatnashsin")}</span>
                                )}
                            </div>
                            <div>
                                {/[^A-Za-z0-9]+/.test(values.new_password) ? (
                                    <span className={style.check}>✓ {t("Parolda maxsus belgi qatnashsin. Masalan: !@#$%^&*+-=.")}</span>
                                ) : (
                                    <span className={style.dont}>✗ {t("Parolda maxsus belgi qatnashsin. Masalan: !@#$%^&*+-=.")}</span>
                                )}
                            </div>
                            <div>
                                {values.new_password && values.new_password === values.confirm_password ? (
                                    <span className={style.check}>✓ {t("Parolni takrorlash")}</span>
                                ) : (
                                    <span className={style.dont}>✗ {t("Parolni takrorlash")}</span>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default ChangePassword;
