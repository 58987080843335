import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import DropDown from "./DropDown";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";

const NowCheckin = () => {
  const [guestinfo, setGuestInfo] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const {t} = useTranslation()
  const onSearch = async (e) => {
    setGuestInfo([]);
    if (e?.length) {
      setLoading(true);
      setOverlay(true);
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/hdp-gateway/guest/search?query=${e}`,
          {
            headers: { authorization: sessionStorage.getItem("token") },
          }
        )
        .then((res) => {
          setData(res.data.data);
          setLoading(false);
          setOverlay(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
          setLoading(false);
          setOverlay(false);
        });
    } else {
      getAxios();
    }
  };

  const columns = [
    {
      field: "room_number",
      headerName: t("Room number"),
      width: 330,
    },
    {
      field: "first_name",
      headerName: t("First name"),
      width: 330,
    },
    {
      field: "last_name",
      headerName: t("Last name"),
      width: 330,
    },
    {
      field: "file_uuid",
      headerName: t("Action"),
      width: 300,
      renderCell: () => {
        return <DropDown data={guestinfo} />;
      },
    },
  ];
  
  const getAxios = async () => {
    setLoading(true);
    setGuestInfo([])
    await axios
      .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/guest`, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
        setLoading(false);
      });
  };
  const onRowsSelectionHandler = (ids) => {
    setGuestInfo([]);
      const selectedRowsData = ids.map((id) =>
        data.find((row) => row.id === id)
      );
      setGuestInfo(selectedRowsData[0]);
  };
  useEffect(() => {
    getAxios();
  }, []);
  return (
    <div className="container mt-5">
      {overlay && <div className="overlay" />}
      <div className="d-flex justify-content-between align-items-center">
        <h5>{t("now check in")}</h5>
        <input
          type="text"
          placeholder={t("Room number, First name, Last name")}
          className="form-control  w-75"
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="mt-5">
          <DataGrid
            {...data}
            initialState={{
              ...data.initialState,
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25, 100]}
            autoHeight
            rows={data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          />
        </div>
      )}
    </div>
  );
};

export default NowCheckin;