import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ getData }) {
    const { t } = useTranslation();
    const qy = document.querySelector.bind(document);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  const [share, setShare] = React.useState(false);
    
  const onChange = (e) => {
    setShare(e.target.checked);
  };

    const allRegEx = /^[A-Za-z0-9' ]*$/;
    const regex = /^[A-Za-z]{1,1}[a-zA-Z0-9- ’'.,]{0,}[a-z0-9]{1,}$/;
    const validate = Yup.object({
        name: Yup.string()
            .required(t("Field is required"))
            .matches(regex, { message: t("value invalid") }),
        description: Yup.string().optional("description is required").nullable().matches(allRegEx, { message: "invalid value" }),
        price: Yup.number().required("Field is required"),
        number_of_people: Yup.string().required(t("Field is required")),
        file: Yup.string().optional().nullable(),
    });
    const emptyData = {
        name: "",
        description: "",
        price: "",
        number_of_people: "",
        file: "",
    };

    const onSubmit = async (values) => {
        const body = new FormData();

        body.append("name", values.name);
        body.append("price", values.price);
        body.append("number_of_people", values.number_of_people);
        body.append("is_shared", share);
        if (values?.description) {
            body.append("description", values.description);
        }
        if (qy(`#file`)?.files[0]) {
            body.append("file", qy(`#file`).files[0]);
        }

        setLoading(true);
        await axios
            .post(`${process.env.REACT_APP_API_URL}/hdp-gateway/room/category`, body, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Saved successfully"));
                handleClose();
                getData();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
    };

    return (
        <div className="modal_res">
            <Button onClick={handleOpen} variant="contained">
                {t("Add Category")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="modal_res">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter category info")}
                        </Typography>
                        <Formik
                            initialValues={emptyData}
                            validationSchema={validate}
                            onSubmit={async (values) => {
                                onSubmit(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <Field fullWidth name="name" size="small" component={TextField} label={t("Name")} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field fullWidth name="description" size="small" component={TextField} label={t("Description")} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field fullWidth name="price" size="small" component={TextField} label={t("Price")} type="number" />
                                        </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="number_of_people"
                        size="small"
                        component={TextField}
                        label={t("Number of people")}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkbox onChange={onChange}>{t("Share")}</Checkbox>
                    </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Field id={`file`} fullWidth size="small" name={`file`} component={TextField} type="file" />
                                        </Grid>
                                    </Grid>
                                    <div className="right_left">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                            {t("Close")}
                                        </Button>
                                        <>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {t("Submit")}
                                                </LoadingButton>
                                            ) : (
                                                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {t("Submit")}
                                                </Button>
                                            )}
                                        </>
                                    </div>
                                    {loading && <div className="overlay" />}
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
