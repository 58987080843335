import { Alert, Button, Divider, Grid, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import { Form, Formik, Field } from "formik";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useState } from "react";
import * as Yup from "yup";
import axios from "axios";

const validate = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  room_category: Yup.number().required("Category is required"),
  room_id: Yup.number().required("Room is required"),
  type: Yup.number().required("Type is required"),
  amount: Yup.number().required("Amount is required"),
});

function ModalCalendar({ modalStatus, handleClose, handleSave, startDate, endDate, eventInput, editStatus, handleDelete, categories, types, loading }) {
  const {t} = useTranslation()
  const [rooms, setRooms] = useState([]);

  const initialVal = { 
    first_name: "", 
    last_name: "", 
    room_category: "", 
    room_id: "", 
    start_date: startDate, 
    end_date: endDate, 
    type: "", 
    amount: "",
    comments: ""
  }

  const onChange = async (e,values) => {
    values.room_category = e.target.value
    if(e){
      values.room_id = ""
      await axios.get(`${process.env.REACT_APP_API_URL}/hdp-gateway/room/cat/${e.target.value}`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      }).then((res) => setRooms(res.data.data))
      .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
    }
  }

  const onCalculateTotal = (e, event) => {
    event.room_id = e.target.value;
    rooms.map((el, i) => {
     if(el.id === event.room_id){
        const start = new Date(event.start_date);
        const end = new Date(event.end_date);
        const difference_time  = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if((days-1) <= 0) event.amount = el.price;
        else event.amount = (days-1) * el.price;
      }
    });
  }

  return (
    <>
      <Modal show={modalStatus} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton><Modal.Title>{eventInput.id ? t("Update booking") : t("Create new booking")}</Modal.Title></Modal.Header>
        <Modal.Body className="mt-2">
          {
            <Formik initialValues={eventInput ? eventInput : initialVal} validationSchema={validate} onSubmit={(values) => handleSave(values)}>
              {({ values }) => (
                <Form autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field size="small" fullWidth name="start_date" label="Start date" value={startDate.toISOString()?.slice(0,10)} disabled component={TextField}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field size="small" fullWidth name="end_date" label="End date" value={endDate.toISOString()?.slice(0,10)} disabled component={TextField}/>
                    </Grid>
                    <Grid item xs={12}><Divider className="my-1"/></Grid>
                    <Grid item xs={12} sm={6}>
                      <Field size="small" fullWidth name="first_name" disabled={loading} label="First name" component={TextField}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field size="small" fullWidth name="last_name" disabled={loading} label="Last name" component={TextField}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {categories.length ? (
                        <Field fullWidth select name="room_category" size="small" disabled={eventInput || loading} 
                          component={TextField} label="Select Category" onChange={(e)=>onChange(e, values)}>
                          {categories.map((item, index) => <MenuItem value={item?.id} key={index}>{item?.name}</MenuItem>)}
                        </Field>
                      ) : (
                        <Alert severity="info">
                          You don't have any categories yet. Please add some
                          categories and came here.
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {
                        eventInput?.id ? 
                        <Field fullWidth select name="room_id" size="small" component={TextField} label="Select room" disabled>
                          <MenuItem selected value={eventInput?.room_id} key={eventInput?.id}>{eventInput?.room_number} - xona</MenuItem>
                        </Field> :
                        <Field fullWidth select name="room_id" size="small" component={TextField} label="Select Room" 
                          disabled={(!rooms?.length || loading) ? true : false} onChange={(e) => onCalculateTotal(e, values)}>
                          {rooms.map((item, index) => 
                            <MenuItem value={item.id} key={index}>{`${item.number}-xona,  ${item.number_of_people}-kishilik`}</MenuItem>
                          )}
                        </Field>
                      }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {types.length ? (
                        <Field fullWidth select name="type" size="small" disabled={loading} component={TextField} label="Select type">
                          {types.map((item, index) => <MenuItem value={item.id} key={index}>{item.name}</MenuItem>)}
                        </Field>
                      ) : ( <Alert severity="info">You don't have any types.</Alert> )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field size="small" fullWidth label="Amount" disabled  name="amount" component={TextField} type="number"/>
                    </Grid>
                    {
                      eventInput.id ?
                      <Grid item xs={12} sm={4}>
                        <Field size="small" fullWidth label="Debit" disabled name="debit" component={TextField} type="number"/>
                      </Grid> : null
                    }
                    {
                      eventInput.id ?
                      <Grid item xs={12} sm={4}>
                        <Field size="small" fullWidth label="Money due" disabled value={eventInput.amount-eventInput.credits} name="due" component={TextField} type="number"/>
                      </Grid> : null
                    }
                    <Grid item xs={12} sm={eventInput?.id ? 4 : 6}>
                      <Field size="small" fullWidth label="Credit" name="credit" component={TextField}/>
                    </Grid>
                    <Grid item xs={12} sm={eventInput.id ? 12 : 6}>
                      <Field size="small" fullWidth label="Comment" disabled={loading} name="comments" component={TextField}/>
                    </Grid>
                  </Grid>
                  <Modal.Footer className="right_left mt-4">
                    <Button onClick={handleClose} variant="contained" disabled={loading} color="error">Close</Button>
                    <div>
                      {editStatus && <Button variant="secondary" disabled={loading} onClick={handleDelete}><DeleteIcon fontSize="small" /></Button>}
                      <Button type="submit" variant="contained" disabled={loading} color="success" className="ms-3">Submit</Button>
                    </div>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          }
        </Modal.Body>
      </Modal>
    </> 
  );
}

export default ModalCalendar;
