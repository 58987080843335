import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Loading from "../../Loading";
import axios from "axios";
import "./style.css";

const CategoryAmenities = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [archive, setArchive] = useState(false);
    const [data, setData] = useState([]);
    const [category, setCategory] = useState({});

    const getData = async () => {
        // setLoading(true);
        await axios
            .get(`${ process.env.REACT_APP_API_URL}/hdp-gateway/room/category-amenity/${id}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setCategory(res.data.data.category);
                setArchive(res.data.data.data.map((group) => ({ name: group.name, amenities: group.amenities.map((e) => ({ ...e })) })));
                setData(res.data.data.data.map((group) => ({ name: group.name, amenities: group.amenities.map((e) => ({ ...e })) })));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const handleChange = (event, id) => {
        if (updating)
            setData(
                data.map((group) => ({
                    name: group.name,
                    amenities: group.amenities.map((amenity) => {
                        if (amenity.id === id) amenity.have = event.target.checked;
                        return amenity;
                    }),
                }))
            );
    };

    const update = () => {
        setUpdating(true);
    };

    const cancel = () => {
        setUpdating(false);
        setData(archive.map((group) => ({ name: group.name, amenities: group.amenities.map((e) => ({ ...e })) })));
    };

    const submit = async () => {
        setWaiting(true);
        await axios
            .put(
                `${ process.env.REACT_APP_API_URL}/hdp-gateway/room/category-amenity`,
                { category_id: id, data },
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then(() => {
                getData();
                setWaiting(false);
                setUpdating(false);
                toast.success(t("Success"));
            })
            .catch((err) => {
                console.log(err);
                setWaiting(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
    };

    return (
        <div className="container mt-3">
            {loading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    {waiting && <div className="overlay"></div>}
                    {updating ? (
                        <div style={{ float: "right" }}>
                            <Button variant="outlined" color="error" onClick={cancel}>
                                {t("Cancel")}
                            </Button>
                            <Button style={{ marginLeft: "10px" }} variant="outlined" color="primary" onClick={submit}>
                                {t("Submit")}
                            </Button>
                        </div>
                    ) : (
                        <Button style={{ float: "right" }} variant="outlined" onClick={update}>
                            {t("Update")}
                        </Button>
                    )}
                    <h3>
                        {category.name} {t("category amenities")}
                    </h3>
                    {data.map((group) => (
                        <React.Fragment>
                            <h4 className="mt-4">{group.name}</h4>
                            <div className="hc-amenity no-select">
                                {group.amenities.map((amenity) => (
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox checked={amenity.have} onChange={(e) => handleChange(e, amenity.id)} />}
                                            label={amenity.name}
                                        />
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )}
        </div>
    );
};

export default CategoryAmenities;
