import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormControls, FormControlWrapper, ListBtn } from "./ListStyle";
import { FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { createGet } from "../Context/GetRolesContext";

const List = () => {
    const {t} = useTranslation()
    const [get,setGet] = useContext(createGet)
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [user_id, setUser_id] = useState("");
    const [userRoles, setUserRoles] = useState([]);
    const [boards, setBoards] = useState([
        {
            id: 1,
            title: t("Existing Rules"),
            items: [],
        },
    ]);
    const [boards2, setBoards2] = useState([
        {
            id: 1,
            title:t("Current Rules"),
            items: [],
        },
    ]);
    const [currentBoard, setCurrentBoard] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    const dragOverHandler = (e) => {
        e.preventDefault();
    };
    const dragLeaveHandler = (e) => {
        e.target.style.boxShadow = "none";
    };
    const dragStartHandler = (e, board, item) => {
        setCurrentBoard(board);
        setCurrentItem(item);
    };
    const DropHandler = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setUserRoles([...userRoles, currentItem]);
        setBoards2(
            boards.map((b) => {
                if (b.id === board.id) {
                    return board;
                }
                if (b.id === currentBoard.id) {
                    return currentBoard;
                }
                return b;
            })
        );
    };
    const DropHandler2 = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setRoles([...roles, currentItem]);
        setBoards(
            boards.map((b) => {
                if (b.id === board.id) {
                    return board;
                }
                if (b.id === currentBoard.id) {
                    return currentBoard;
                }
                return b;
            })
        );
    };
    const dragEndHandler = (e) => {
        e.target.style.boxShadow = "none";
    };
    function getAllRoles() {
        axios
            .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/user/roles`, {
                headers: {
                    Authorization:sessionStorage.getItem("token")
                },
            })
            .then((response) => {
                setData(response.data.data);
                setRoles(response.data.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
            });
    }

    function getUsers() {
        axios
            .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/user`, {
                headers: {
                    Authorization: sessionStorage.getItem("token")
                },
            })
            .then((response) => {
                setUsers(response.data.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
            });
    }

    const handleChange = (e) => {
        setBoards([
            {
                id: 1,
                title:t("Existing Rules"),
                items: [],
            },
        ]);
        setBoards2([
            {
                id: 1,
                title:t("Current Rules"),
                items: [],
            },
        ]);
        setUser_id(e.target.value);
        axios
            .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/user/user_roles/${e.target.value.id}`, {
                headers: {
                    Authorization:sessionStorage.getItem("token")
                },
            })
            .then((response) => {
                setRoles(data.filter((value) => response.data.data.map((e) => e.id).indexOf(value.id) === -1));
                setUserRoles(response.data.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
            });
    };

    const onSave = () => {
        setLoading(true);
        const data = {
            staff_id: user_id.id,
            roles: [],
        };
        for (let i = 0; i < userRoles.length; i++) {
            data.roles.push(userRoles[i].id);
        }
        axios
            .patch(`${process.env.REACT_APP_API_URL}/hdp-gateway/user/role_assignment`, data, {
                headers: {
                    Authorization:sessionStorage.getItem("token")
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Saved successfully"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
            });
    };

    useEffect(() => {
        getAllRoles();
        getUsers();
    }, []);
    useEffect(() => {
        setBoards([
            {
                id: 1,
                title:t("Existing Rules"),
                items: [],
            },
        ]);
        setBoards2([
            {
                id: 1,
                title:t("Current Rules"),
                items: [],
            },
        ]);
      }, [!get]);
    return (
        <div className="container">
            <FormControlWrapper>
                <FormControls>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Select User</InputLabel>
                        <Select
                            placeholder="Select User"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={user_id}
                            label="Select User"
                            onChange={handleChange}
                        >
                            {users.map((value, index) => {
                                return (
                                    <MenuItem key={index} value={value}>
                                        {value.first_name} {value.last_name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </FormControls>
            </FormControlWrapper>
            <div className="DropContainer">
                {boards.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler2(e, roles, 1)}>
                            <div className="Board_title" key={value.id}>
                                {value.title}
                            </div>
                            {roles.map((value) => {
                                return (
                                    <div
                                        className="drop_item"
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragStart={(e) => dragStartHandler(e, roles, value)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                        draggable={true}
                                        key={value.id}
                                    >
                                        {value.role}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                {boards2.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler(e, value, 1)}>
                            <div className="Board_title" key={value.id}>
                                {value.title}
                            </div>
                            {userRoles.map((prod) => {
                                return (
                                    <div
                                        className="drop_item"
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragStart={(e) => dragStartHandler(e, userRoles, prod)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                        draggable={true}
                                        key={prod.id}
                                    >
                                        {prod.role}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <ListBtn>
                {loading ? (
                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                        {t("Save")}
                    </LoadingButton>
                ) : (
                    <Button onClick={onSave} variant="contained" color="success" disabled={user_id && userRoles.length ? false : true}>
                       {t("Save")}
                    </Button>
                )}
            </ListBtn>
        </div>
    );
};

export default List;