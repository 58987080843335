import { useEffect, useState } from 'react';
import axios from "axios";

import Rooms from '../components/Rooms/Table'
import Roomsnew from '../components/Roomsnew/index.jsx'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIn from "../components/CheckIn/Index";
import GuestInfoModal from "../components/Roomsnew/GuestInfoModal";
import CheckOutPage from "../components/CheckOut/CheckOut";
import { toast } from "react-toastify";
import CleaningButtonGroup from "../components/Roomsnew/CleaningButtonGroup";
import { Button, ButtonGroup, Menu, MenuItem, Modal } from '@mui/material';
import Transferroom from "../components/Roomsnew/transfer";
import UpdateCheckIn from "../components/CheckIn/Update.jsx"
import UpdateBooking from "../components/Shaxmatka/Booking/UpdateBooking.jsx"

// Icons
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';

// Modals
import RoomsFilters from "../components/Shaxmatka/Filters/Filters.jsx";
import RoomsSort from "../components/Shaxmatka/Sorts/Sort.jsx";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function RoomsPage() {
  const navigate = useNavigate();
  const [activeView, setActiveView] = useState('card');
  const [value, setValue] = useState(1);
  const [rooms, setRooms] = useState([]);

  // Modals
  const [filtersModal, setFiltersModal] = useState(false);
  const [sortsModal, setSortsModal] = useState(false);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [housekeepers, sethousekeepers] = useState([]);
  const [roomhousekeeper, setroomhousekeeper] = useState([]);
  const [policys, setpolicys] = useState({ data: { check_out_time: "" } });
  const [currentItemValue, setCurrentItemValue] = useState(null);
  const [bookingTypes, setBookingTypes] = useState([]);

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, value) => {
    setAnchorEl(event);
    setCurrentItemValue(value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Filter
  const [filter, setFilterProp] = useState({
    number: null,
    status: "",
    category: "",
    priceRange: [0, Infinity],
    minValue: 0,
    maxValue: Infinity
  });

  // Sort
  const [sort, setSortProp] = useState({
    orderBy: "sortNumber",
    sortBy: "asc"
  });

  // State for opening modals
  // - Guest
  const [openGuestList, setOpenGuestList] = useState(false);
  const handleOpenGuestList = () => { setOpenGuestList(true); setAnchorEl(false); };
  const handleCloseGuestList = () => setOpenGuestList(false);

  // - Check in
  const [openCheckin, setOpenCheckin] = useState(false);
  const handleOpenCheckin = () => { setOpenCheckin(true); setAnchorEl(false); };
  const handleCloseCheckin = () => setOpenCheckin(false);

  // - Check out
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const handleOpenCheckOut = () => { setOpenCheckOut(true); setAnchorEl(false); }
  const handleCloseCheckOut = () => setOpenCheckOut(false);

  // Check out page
  const [openCheckOutPage, setOpenCheckOutPage] = useState(false);
  const handleOpenCheckOutPage = () => {
    handleCloseCheckOut();
    setOpenCheckOutPage(true);
    setAnchorEl(false);
  };
  const handleCloseCheckOutPage = () => {
    fetchData();
    setOpenCheckOutPage(false)
  };

  // Transfer
  const [openTransfer, setOpenTransfer] = useState(false);
  const handleOpenTransfer = () => { setOpenTransfer(true); setAnchorEl(false); }
  const handleCloseTransefer = () => setOpenTransfer(false);

  // Update Check-In
  const [updateCheckInModal, setUpdateCheckInModal] = useState(false);
  const handleOpenUpdateCheckIn = () => {
    setUpdateCheckInModal(true);
    setAnchorEl(false);
  }
  const handleCloseUpdateCheckIn = () => setUpdateCheckInModal(false);

  // Update Booking
  const [updateBookingModal, setUpdateBookingModal] = useState(false);
  const handleOpenUpdateBooking = () => {
    setUpdateBookingModal(true);
    setAnchorEl(false);
  }
  const handleCloseUpdateBooking = () => setUpdateBookingModal(false);


  const fetchData = async () => {
    setLoading(true);
    setAnchorEl(null);
    setCurrentItemValue(null);
    try {
      const token = sessionStorage.getItem("token");

      const categoriesResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/hdp-gateway/room/category/list`,
        {
          headers: { Authorization: token },
        }
      );
      setCategories(categoriesResponse.data.data);

      const housekeepersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/hdp-gateway/user/housekeepers`,
        {
          headers: { Authorization: token },
        }
      );

      sethousekeepers(housekeepersResponse.data.data);

      const roomhousekeeperResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/hdp-gateway/user/rooms-housekeeper`,
        {
          headers: { Authorization: token },
        }
      );
      setroomhousekeeper(roomhousekeeperResponse.data.data);

      const policy = await axios.get(
        `${process.env.REACT_APP_API_URL}/hdp-gateway/hotel/policy`,
        {
          headers: { Authorization: token },
        }
      );
      setpolicys(policy.data.data?.data?.check_out_time?.split(":")[0] || 12);

      const currentDate = new Date().toJSON().slice(0, 10);

      const reservationQuery = `?start_date=${currentDate}&end_date=${currentDate}`;
      const reservationsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/hdp-gateway/booking/v2${reservationQuery}`,
        {
          headers: { Authorization: token },
        }
      );

      const reservations = reservationsResponse.data.data.roomCategories;

      const roomsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/hdp-gateway/room`, {
        headers: { Authorization: token },
      });

      const fetchedRooms = roomsResponse.data.data;

      const currentHour = new Date().getHours();
      const today = new Date().toISOString().split("T")[0];

      const roomsWithReservations = fetchedRooms.map((room) => {
        let reservationsForRoom = [];

        reservations.forEach((category) => {
          const foundRoom = category.rooms.find((r) => r.id === room.id);
          if (foundRoom && foundRoom.reservations) {
            reservationsForRoom = foundRoom.reservations.map((reservation) => ({
              ...reservation,
              notice: reservation.noticeData || { credits: 0, debits: 0 }, // Assign notice or fallback
            }));

            // Filtrlaymiz: soatga qarab va end_date ning bugungi kunga tegishli bo'lishiga qaraymiz
            const checkReservations = reservationsForRoom.filter(
              (res) =>
                (currentHour < policys &&
                  new Date(res.end_date).toISOString().split("T")[0] === today) ||
                (currentHour >= policys &&
                  new Date(res.end_date).toISOString().split("T")[0] >= today)
            );

            // Xona statusini yangilash
            if (checkReservations.length > 0) {
              const currentReservation = checkReservations.find(
                (res) => res.bookingStatus === 1 || res.bookingStatus === 2
              );
              if (currentReservation) {
                if (currentReservation.bookingStatus === 1) {
                  room.status_name = "busy";
                  room.status = 2;
                } else if (currentReservation.bookingStatus === 2) {
                  room.status_name = "Booked";
                  room.status = 6;
                }
              }
            }

            // Agar rezervatsiyalar ro'yxatida bookingStatus === 2 bo'lgan rezervatsiya bo'lsa, xonani 'booked' holatiga o'zgartiramiz
            if (reservationsForRoom.some(res => res.bookingStatus === 2)) {
              room.status = 6;
              room.status_name = "Booked";
            }
          }
        });

        return { ...room, reservations: reservationsForRoom };
      });

      setRooms(roomsWithReservations);

      setFilterProp((prev) => {
        return { ...prev, minValue: Math.min(...roomsWithReservations.map((room) => room.price)), maxValue: Math.max(...roomsWithReservations.map((room) => room.price)) }
      });

    } catch (err) {
      toast.error(
        err?.response?.data?.errors[0]?.message || t("An error occurred")
      );
    } finally {
      setLoading(false);
    }
  };

  const getBookingTypes = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/booking/types`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => setBookingTypes(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
    getBookingTypes();
  }, []);

  const handleChange = (view) => {
    setActiveView(view);
    setValue(view === 'table' ? 0 : 1);
  };

  // Filtering and sorting rooms
  let currentRooms = rooms
    .filter((room) => {
      if (filter.number) {
        return room?.number?.toLowerCase().includes(filter.number?.toLowerCase());
      }
      return true;
    })
    .filter((room) => {
      if (filter.status) {
        return room.status_name === filter.status;
      }
      return true;
    })
    .filter((room) => {
      if (filter.category) {
        return room.category_name === filter.category;
      }
      return true;
    })
    .filter((room) => {
      return room.price >= (filter.priceRange[0] || 0) && room.price <= (filter.priceRange[1] || Infinity)
    })

  if (sort.orderBy === "sortNumber") {
    currentRooms = currentRooms.sort((a, b) => {
      if (sort.sortBy === "asc") {
        return a.number - b.number;
      } else {
        return b.number - a.number;
      }
    })
  } else if (sort.orderBy === "sortPrice") {
    currentRooms = currentRooms.sort((a, b) => {
      if (sort.sortBy === "asc") {
        return a.price - b.price;
      } else {
        return b.price - a.price;
      }
    });
  }

  return (
    <Box sx={{ width: '100%' }}>
      <h5 style={{ paddingLeft: "36px" }}>{t("All rooms")}</h5>

      {/* Header buttons */}
      <CustomTabPanel value={value} index={value} sx={{ paddingBottom: 0 }}>
        <div className="container d-flex justify-content-between" style={{ display: "flex", justifyContent: "flex-end", gap: "10px", }}>
          <ButtonGroup variant="outlined" aria-label="outlined primary button group">
            <Button
              variant={activeView === 'table' ? 'contained' : 'outlined'}
              onClick={() => handleChange('table')}
            >
              <TableRowsOutlinedIcon fontSize='medium' />
            </Button>
            <Button
              variant={activeView === 'card' ? 'contained' : 'outlined'}
              onClick={() => handleChange('card')}
            >
              <GridViewOutlinedIcon fontSize='medium' />
            </Button>
          </ButtonGroup>
          {!loading && <div style={{ display: "flex", gap: "5px" }}>
            <Button variant="contained" sx={{ minWidth: '36px !important', padding: 0 }} color="primary" onClick={() => setFiltersModal(true)}>
              <TuneRoundedIcon />
            </Button>
            <Button variant="contained" sx={{ minWidth: '36px !important', padding: 0 }} color="primary" onClick={() => setSortsModal(true)}>
              <SortRoundedIcon />
            </Button>
          </div>}
        </div>
      </CustomTabPanel>
      {/* /Header buttons */}

      {/* Menu */}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 64 * 4.7,
            overflow: 'visible',
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            marginTop: '4px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem disabled={currentItemValue?.status !== 1 && currentItemValue?.status !== 6} onClick={handleOpenCheckin}>
          {t("Check in")}
        </MenuItem>
        <MenuItem disabled={currentItemValue?.status !== 2} onClick={handleOpenUpdateCheckIn}>
          {t("Update Check-In")}
        </MenuItem>
        <MenuItem disabled={currentItemValue?.status != 6} onClick={handleOpenUpdateBooking}>
          {t("Update Booking")}
        </MenuItem>
        <MenuItem disabled={currentItemValue?.status === 1} onClick={() => navigate(`/housekeeping-assignmentv2`)}>{t("Room assignment")}</MenuItem>
        <CleaningButtonGroup disabled={currentItemValue?.status !== 2 && currentItemValue?.status !== 3} fetchData={fetchData} roomhousekeeper={roomhousekeeper} housekeepers={housekeepers} room_id={currentItemValue?.id} />
        <MenuItem onClick={handleOpenGuestList} disabled={currentItemValue?.status !== 2}>{t("Guest")}</MenuItem>
        <MenuItem disabled={currentItemValue?.status !== 2} onClick={() => handleOpenCheckOut()} >{t("Check out")}</MenuItem>
        <MenuItem disabled={currentItemValue?.status !== 2} onClick={() => handleOpenTransfer()} >{t("Transfer")}</MenuItem>
      </Menu>
      {/* /Menu */}

      {/* Modals */}
      {/* - Guest List */}
      <Modal
        open={openGuestList}
        onClose={handleCloseGuestList}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: handleCloseGuestList,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            maxWidth: '780px',
            width: '100%',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseGuestList}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {currentItemValue?.guests && <GuestInfoModal guestData={currentItemValue?.guests} reservations={currentItemValue?.reservations} />}
        </Box>
      </Modal>

      {/* - Check In */}
      <Modal
        open={openCheckin}
        onClose={handleCloseCheckin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: handleCloseCheckin,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            maxWidth: '780px',
            maxHeight: '90%',
            overflowY: "scroll"
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseCheckin}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CheckIn getRoomdata={() => { setOpenCheckin(false); fetchData(); }} defroom_id={currentItemValue?.id} defcategory={currentItemValue?.category} />
        </Box>
      </Modal>

      {/* - Check Out */}
      <Modal
        open={openCheckOut}
        onClose={handleCloseCheckOut}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: handleCloseCheckOut,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            width: '70vw',
            maxHeight: '90%',
            overflowY: "scroll"
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseCheckOut}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CheckOutPage getRoomdata={() => { setOpenCheckOut(false); fetchData(); }} id={currentItemValue?.guests[0]?.booking_id} handleOpenCheckouttwopage={handleOpenCheckOutPage} />
        </Box>
      </Modal>

      {/* - Transfer */}
      <Modal
        open={openTransfer}
        onClose={handleCloseTransefer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: handleCloseTransefer,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            width: '70vw',
            maxHeight: '90%',
            overflowY: "scroll"
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseTransefer}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Transferroom getRoomdata={() => { handleCloseTransefer(); fetchData(); }} transferdata={currentItemValue} getData={fetchData} categories={categories} />
        </Box>
      </Modal>

      {/* Update Check-In */}
      <UpdateCheckIn show={updateCheckInModal} setShow={setUpdateCheckInModal} id={currentItemValue?.guests?.[0]?.booking_id} categories={categories} getData={fetchData} currentItem={currentItemValue} />

      {/* Update Booking */}
      <UpdateBooking show={updateBookingModal} setShow={setUpdateBookingModal} bookingTypes={bookingTypes} bookingData={
        {
          booking_id: currentItemValue?.reservations?.[0]?.booking_id,
          first_name: currentItemValue?.reservations?.[0]?.first_name,
          last_name: currentItemValue?.reservations?.[0]?.last_name,
          start_date: currentItemValue?.reservations?.[0]?.start_date,
          end_date: currentItemValue?.reservations?.[0]?.end_date,
          room: {
            id: currentItemValue?.id,
            category: {
              id: currentItemValue?.category
            }
          },
          bookingType: currentItemValue?.reservations?.[0]?.bookingType,
          noticeData: currentItemValue?.reservations?.[0]?.noticeData,
          bookingComment: currentItemValue?.reservations?.[0]?.bookingComment
        }
      }
        categories={categories} getData={fetchData} />

      {/* / Modals */}

      {/* Filter and Sort */}
      <RoomsFilters
        show={filtersModal}
        setShow={setFiltersModal}
        categories={categories}
        filterProp={filter}
        setFilterProp={setFilterProp}
        getDataWithFilter={fetchData}
      />
      <RoomsSort
        show={sortsModal}
        setShow={setSortsModal}
        sortProp={sort}
        setSortProp={setSortProp}
        getDataWithFilter={fetchData}
      />
      {/* /Filter and Sort */}

      {/* Views */}
      <CustomTabPanel value={value} index={0}>
        <Rooms roomsProp={currentRooms} isLoading={loading} handleClick={handleClick} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Roomsnew roomsProp={currentRooms} isLoading={loading} fetchData={fetchData} handleClick={handleClick} housekeepers={housekeepers} categories={categories} roomhousekeeper={roomhousekeeper} />
      </CustomTabPanel>
      {/* /Views */}
    </Box>
  );
} 
