import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";
// TransferRoom component
const TransferRoom = ({ transferdata, getData, categories, getRoomdata }) => {
  const [rooms, setRooms] = useState([]);
  const [catWaiting, setCatWaiting] = useState(false);

  const differenceAmount = 2100; // Example value for difference amount
  const differenceAmountForOneDay = 2100; // Example value for one-day difference

  // Function to handle category change and fetch rooms for that category
  const changeCategoryHandler = async (category, values, setValues) => {
    if (category) {
      setCatWaiting(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/hdp-gateway/room/cat/${category}`, {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setRooms(res.data.data.map((room) => ({ value: room.id, label: room.number, price: room.price })));
        setValues({ ...values, room_category: category, room_id: "" });
      } catch (error) {
        toast.error("An error occurred while fetching rooms");
      } finally {
        setCatWaiting(false);
      }
    }
  };

  // Validation schema for the form
  const validationSchema = Yup.object({
    room_id: Yup.string().required("Room is required"),
    credit: Yup.number().required("Credit is required"),
    amount: Yup.number().required("Amount is required"),
    comments: Yup.string().optional(),
  });

  // Form submission handler
  const submitHandler = async (values, { setSubmitting }) => {
    try {
      const body = {
        room_id: values.room_id,
        credit: values.credit,
        debit: values.amount,
        comments: values.comments,
        id: transferdata.guests[0].booking_id,
        guestsLength: transferdata.guests.length,
        guests: transferdata.guests.map((guest) => ({
          id: guest.id
        })),
      };

      await axios.put(`${process.env.REACT_APP_API_URL}/hdp-gateway/check-in/transfer`, body, {
        headers: { Authorization: sessionStorage.getItem("token"), "Content-Type": "application/json" },
      });

      getData();
      getRoomdata();
      toast.success("Transfer successful");
    } catch (error) {
      toast.error("An error occurred during the transfer");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        room_category: transferdata?.category || "",
        room_id: transferdata?.id || "",
        credit: 0,
        amount: 0,
        comments: transferdata.bookingComment || "",
      }}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {({ values, setValues, isSubmitting }) => (
        <Form style={containerStyle}>
          <h4 style={headerStyle}>Transfer Room</h4>

          {/* Category Field */}
          <div style={formGroupStyle}>
            <label style={labelStyle}>Category</label>
            <Field
              as="select"
              name="room_category"
              onChange={(e) => changeCategoryHandler(e.target.value, values, setValues)}
              style={inputStyle}
            >
              <option value="">Select a Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Field>
          </div>

          {/* Room Field */}
          <div style={formGroupStyle}>
            <label style={labelStyle}>Room</label>
            <Field as="select" name="room_id" disabled={catWaiting || rooms.length === 0} style={inputStyle}>
              <option value="">Select a Room</option>
              {rooms.map((room) => (
                <option key={room.value} value={room.value}>
                  {room.label}
                </option>
              ))}
            </Field>
          </div>

          {/* Move and End Dates */}
          <div style={formGroupStyle}>
            <label style={labelStyle}>Move Date</label>
            <input
              type="text"
              value={dayjs(transferdata.move_date || new Date()).format("DD/MM/YYYY")}
              disabled
              style={disabledInputStyle}
            />
          </div>
          <div style={formGroupStyle}>
            <label style={labelStyle}>End Date</label>
            <input
              type="text"
              value={dayjs(transferdata.end_date || new Date()).format("DD/MM/YYYY")}
              disabled
              style={disabledInputStyle}
            />
          </div>

          <h4 style={headerStyle}>Additional Payment</h4>

          {/* Row for Difference Amounts */}
          <div style={rowStyle}>
            <div style={formGroupRowStyle}>
              <label style={labelStyle}>Difference Amount:</label>
              <p style={infoTextStyle}>{differenceAmount}</p>
            </div>
            <div style={formGroupRowStyle}>
              <label style={labelStyle}>Difference Amount for One Day:</label>
              <p style={infoTextStyle}>{differenceAmountForOneDay}</p>
            </div>
          </div>

          {/* Row for Amount and Credit */}
          <div style={rowStyle}>
            <div style={formGroupRowStyle}>
              <label style={labelStyle}>Amount</label>
              <Field type="number" name="amount" style={inputStyle} />
            </div>
            <div style={formGroupRowStyle}>
              <label style={labelStyle}>Credit</label>
              <Field type="number" name="credit" style={inputStyle} />
            </div>
          </div>

          {/* Comments */}
          <div style={formGroupStyle}>
            <label style={labelStyle}>Comment</label>
            <Field as="textarea" name="comments" style={textareaStyle} />
          </div>

          {/* Submit Button */}
          <button type="submit" disabled={isSubmitting} style={buttonStyle}>
            {isSubmitting ? "Transferring..." : "Transfer"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

// Inline styles
const containerStyle = {
  display: "flex",
  flexDirection: "column",
  maxWidth: "600px",
  margin: "0 auto",
  padding: "20px",
  border: "1px solid #ddd",
  borderRadius: "8px",
  backgroundColor: "#fff",
};

const formGroupStyle = {
  marginBottom: "15px",
  display: "flex",
  flexDirection: "column",
};

const rowStyle = {
  display: "flex",
  justifyContent: "space-between",
};

const formGroupRowStyle = {
  marginBottom: "15px",
  display: "flex",
  flexDirection: "column",
  width: "48%",
};

const labelStyle = {
  marginBottom: "5px",
  fontWeight: "bold",
};

const infoTextStyle = {
  marginTop: "5px",
  fontSize: "16px",
  color: "#333",
};

const inputStyle = {
  width: "100%",
  padding: "8px",
  fontSize: "16px",
  borderRadius: "4px",
  border: "1px solid #ccc",
};

const disabledInputStyle = {
  width: "100%",
  padding: "8px",
  fontSize: "16px",
  borderRadius: "4px",
  border: "1px solid #e0e0e0",
  backgroundColor: "#f5f5f5",
};

const textareaStyle = {
  width: "100%",
  height: "80px",
  padding: "8px",
  fontSize: "16px",
  borderRadius: "4px",
  border: "1px solid #ccc",
};

const buttonStyle = {
  padding: "12px",
  fontSize: "16px",
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  marginTop: "15px",
};

const headerStyle = {
  fontSize: "18px",
  fontWeight: "bold",
  marginBottom: "20px",
};

export default TransferRoom;
