import React from "react";
import IconButton from "@mui/material/IconButton";
import CircleIcon from "@mui/icons-material/CircleNotifications";
import NotificationMessageModal from "./Message";
import NotificationModal from "./Notification";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import { useEffect } from "react";
import { useState } from "react";

const Notification = () => {
    const { t } = useTranslation();
    const token = sessionStorage.getItem("token");
    const hotelID = sessionStorage.getItem("hotel_id");
    const URL = process.env.REACT_APP_HDP_SOCKET_URL;
    const [isConnected, setIsConnected] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessagesCount, setNewMessagesCount] = useState(0);
    const [show, setShow] = useState(false);
    const [openMessage, setOpenMessage] = useState(null);

    const handleNotificationOpen = (event) => {
        if (show) setOpenMessage(null);
        setShow(!show);
    };

    const handleMessageOpen = (id) => {
        const message = messages.find((e) => e.id === id);
        setOpenMessage(message);
    };

    useEffect(() => {
        const onConnect = () => setIsConnected(true);
        const onDisconnect = () => setIsConnected(false);
        const getMessages = (value) => {
            setMessages(value);
            const newMessages = value.filter((e) => e.status === 1);
            setNewMessagesCount(newMessages.length);
        };

        if (token) {
            const socket = io(URL, { query: { token } });
            socket.on("connect", onConnect);
            socket.on("disconnect", onDisconnect);
            socket.on("notifications", getMessages);
            return () => {
                socket.off("connect", onConnect);
                socket.off("disconnect", onDisconnect);
                socket.off("notifications", getMessages);
            };
        }
    }, [token]);

    return (
        <React.Fragment>
            <IconButton
                size="large"
                aria-label="notification"
                aria-controls="notification"
                aria-haspopup="true"
                onClick={handleNotificationOpen}
                color="inherit"
                className="icon-for-tooltip"
                style={{ "--numberofnotifications": newMessagesCount ? `"${newMessagesCount}"` : 0 }}
            >
                <CircleIcon />
            </IconButton>
            {show && <NotificationModal show={show} setShow={setShow} messages={messages} openMessage={handleMessageOpen} />}
            {openMessage && <NotificationMessageModal show={openMessage} setShow={setOpenMessage} message={openMessage} />}
        </React.Fragment>
    );
};
export default Notification;
