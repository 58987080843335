import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import Alert from "@mui/material/Alert";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UpdateBooking from "./UpdateBooking.jsx";
import { createCheckIn } from "../Context/CheckInContext.js";
import { useTranslation } from "react-i18next";
import AddBooking from "./AddBooking.jsx";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) { return order; }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const {t} = useTranslation()
  const headCells = [
    { id: "room_number", numeric: true, label: t("Room number") },
    { id: "first_name", numeric: true, label: t("First name") },
    { id: "last_name", numeric: true, label: t("Last name") },
    { id: "start_date", numeric: true, label: t("Start date") },
    { id: "end_date", numeric: true, label: t("End date") },
    { id: "booking_id", numeric: true, label: t("Booking id") },
    { id: "comments", numeric: true, label: t("Comment") },
    { id: "actions", numeric: true, label: t("Action") },
  ];
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTable({ error, dataa, getData }) {
  const {t} = useTranslation()
  const [id, setId] = React.useState(null);
  const [idx, setIdx] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchor, setAnchor] = React.useState(null);
  const [data, setData] = useContext(createCheckIn);

  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openMenu = (event, elem) => {
    setIdx(elem);
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => setAnchor(null);

  const onCheckIn = () => {
    setData(idx);
    navigate("/check-in");
  };

  return (
    <div className="container tab">
      <AddBooking getData={getData}/>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        {!error && dataa?.length === 0 && <Alert variant="outlined" severity="warning">{t("No Data yet")}</Alert>}
        {error && <Alert variant="outlined" severity="error">{t("An error occurred")}</Alert>}
        {dataa?.length >= 1 && (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={dataa.length}/>
                <TableBody>
                  {stableSort(dataa, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow sx={{ color: "#fff" }}
                          className={`${ 
                            (row.type === 1 && "green") ||
                            (row.type === 2 && "purple") ||
                            (row.type === 3 && "blue") ||
                            (row.type === 4 && "yellow") ||
                            (row.type === 2 && "orange")
                          }`}
                          hover tabIndex={-1} key={row.id}
                        >
                          <TableCell align="left">{row.room_number}</TableCell>
                          <TableCell align="left">{row.first_name}</TableCell>
                          <TableCell align="left">{row.last_name}</TableCell>
                          <TableCell align="left">{row.start_date?.slice(0, 10)}</TableCell>
                          <TableCell align="left">{row.end_date?.slice(0, 10)}</TableCell>
                          <TableCell align="left">{row.booking_id}</TableCell>
                          <TableCell align="left">{row.comments}</TableCell>
                          <TableCell align="left">
                            {/* <Action/> */}
                            <Box sx={{ flexGrow: 0 }}>
                              <Tooltip title="Actions">
                                <IconButton style={{ borderRadius: "50%", padding: "2px" }} 
                                  onClick={(e, elem = row) => openMenu(e, elem)} sx={{ p: 0 }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                onClick={() => setId(row.id)}
                                sx={{ mt: "23px" }} id="menu-appbar"
                                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                anchorEl={anchor} 
                                open={Boolean(anchor)}
                                onClose={closeMenu}
                              >
                                <MenuItem><UpdateBooking
                                row={idx}
                                getTableData={getData}
                                close={closeMenu}
                                 /></MenuItem>
                                <MenuItem onClick={onCheckIn}><Typography>{t("Check in")}</Typography></MenuItem>
                              </Menu>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={dataa.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Box>
    </div>
  );
}

export default React.memo(EnhancedTable);
