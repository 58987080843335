import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Personal from "./Persanal";
import Address from "./Address";
import Generate from "./Generate";
import UploadLogo from "./UploadLogo";
import Alert from "./Alert";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const HotelActive = () => {
  const {t} = useTranslation()
  const [step, setStep] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  sessionStorage.setItem("hotel", searchParams.get("hotel"));

  const token = sessionStorage.getItem("hotel");

  const getStep = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/fth/step`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res?.data?.data?.step === "personal") {
          setStep("personal");
        } else if (res?.data?.data?.step === "address") {
          setStep("address");
        } else if (res?.data?.data?.step === "generate_id") {
          setStep("generate_id");
        } else if (res?.data?.data?.step === "logo") {
          setStep("logo");
        } else {
          setStep("alert");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };
  useEffect(() => {
    getStep();
  }, []);
  return (
    <>
      {step === "personal" ? (
        <Personal setStep={setStep} token={token} />
      ) : step === "address" ? (
        <Address setStep={setStep} token={token} />
      ) : step === "generate_id" ? (
        <Generate setStep={setStep} token={token} />
      ) : step === "logo" ? (
        <UploadLogo token={token} />
      ) : (
        <Alert />
      )}
    </>
  );
};

export default HotelActive;