import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { FormControlLabel, Grid, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { DesktopOutlined } from "@ant-design/icons";
import { green, grey, yellow, blue, lightGreen } from "@mui/material/colors";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import BedroomChildIcon from "@mui/icons-material/BedroomChild";
import PeopleIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";
import { useHistory, useNavigate } from "react-router-dom"; // React Routerdan yo'naltirishni ishlatish uchun
import Guests from "../components/Guests/Tab";
import Shaxmatka from "../components/Shaxmatka/Index";
import CheckoutPage from "./CheckOutPage";
import RoomsPage from "./RoomsPage";
import BookingPage from "./BookingPage";
import CheckInPage from "./CheckInPage";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";

const notallowedvalues = [1, 2, 3, 5];

function CustomTabPanel(props) {
  const { children, value, index, isPartner, handleOpenMainModal, setValue, ...other } = props;
  if (notallowedvalues.includes(value) && isPartner) {
    handleOpenMainModal();
    setValue(0);
  }
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  isPartner: PropTypes.any.isRequired,
  handleOpenMainModal: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const [available, setAvailable] = React.useState(0);
  const [busy, setBusy] = React.useState(0);
  const [dirty, setDirty] = React.useState(0);
  const [clean, setClean] = React.useState(0);
  const [cleanComplete, setCleanComplete] = React.useState(0);
  const [maintains, setMaintains] = React.useState(0);
  const { handleOpenMainModal, isPartner } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    if (newValue === 4) {
      setValue(newValue);
      const newPath = window.location.pathname.endsWith("/")
        ? `${window.location.pathname}rooms`
        : `${window.location.pathname}/rooms`;
      navigate(newPath); // Yo'naltirishni o'zgartirish sahifa yangilanmasdan amalga oshiriladi
    } else {
      setValue(newValue);
      if (value === 4 && newValue !== 4) {
        const previousPath = window.location.pathname.replace(/\/rooms$/, "");
        navigate(previousPath);
      }
    }
  };


  const getStatus = async () => {
    await axios
      .get(`${ process.env.REACT_APP_API_URL}/hdp-gateway/room/charts`, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        res.data?.data?.forEach((element) => {
          if (element.label === "available") {
            setAvailable(element.value);
          } else if (element.label === "busy") {
            setBusy(element.value);
          } else if (element.label === "dirty") {
            setDirty(element.value);
          } else if (element.label === "cleaning complete") {
            setCleanComplete(element.value);
          } else if (element.label === "cleaning in progress") {
            setClean(element.value);
          } else {
            setMaintains(element.value);
          }
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.errors[0]?.message || t("An error occurred")
        );
      });
  };

  React.useEffect(() => {
    const isroomspage = window.location.pathname.split('/').at(-1) === 'rooms';
    if (isroomspage) {
      setValue(4);
    }
    getStatus();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <div className="card">
        <div className="card-body" style={{ padding: "1px 1px 1px 16px" }}>
          <Grid item xs={12} sx={{ padding: "4px" }}>
            <FormControlLabel
              disabled
              value="end"
              control={
                <Checkbox
                  defaultChecked
                  icon={<BookmarkBorderIcon />}
                  checkedIcon={<BookmarkIcon />}
                  sx={{
                    "&.MuiCheckbox-root": {
                      padding: "1px", // Checkboxdagi umumiy padding
                    },
                  }}
                />
              }
              label={`${t("Available")} : ${available}`}
              labelPlacement="end"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.88rem", textTransform: "uppercase", fontWeight: 500 } }}
            />
            <FormControlLabel
              value="end"
              disabled
              control={
                <Checkbox
                  label="Busy"
                  defaultChecked
                  icon={<BookmarkBorderIcon />}
                  checkedIcon={<BookmarkIcon />}
                  sx={{
                    color: green[800],
                    "&.Mui-checked": {
                      color: green[600],
                    },
                    "&.MuiCheckbox-root": {
                      padding: "1px", // Checkboxdagi umumiy padding
                    },
                  }}
                />
              }
              label={`${t("Busy")} : ${busy}`}
              labelPlacement="end"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.88rem", textTransform: "uppercase", fontWeight: 500 } }}
            />
            <FormControlLabel
              value="end"
              disabled
              control={
                <Checkbox
                  icon={<BookmarkBorderIcon />}
                  checkedIcon={<BookmarkIcon />}
                  label="Dirty"
                  defaultChecked
                  sx={{
                    color: grey[800],
                    "&.Mui-checked": {
                      color: grey[600],
                    },
                    "&.MuiCheckbox-root": {
                      padding: "1px", // Checkboxdagi umumiy padding
                    },
                  }}
                />
              }
              label={`${t("Dirty")} : ${dirty}`}
              labelPlacement="end"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.88rem", textTransform: "uppercase", fontWeight: 500 } }}
            />
            <FormControlLabel
              value="end"
              disabled
              control={
                <Checkbox
                  icon={<BookmarkBorderIcon />}
                  checkedIcon={<BookmarkIcon />}
                  label="Clean"
                  defaultChecked
                  sx={{
                    color: yellow[800],
                    "&.Mui-checked": {
                      color: yellow[600],
                    },
                    "&.MuiCheckbox-root": {
                      padding: "1px", // Checkboxdagi umumiy padding
                    },
                  }}
                />
              }
              label={`${t("Cleaning in progress")} : ${clean}`}
              labelPlacement="end"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.88rem", textTransform: "uppercase", fontWeight: 500 } }}
            />
            <FormControlLabel
              value="end"
              disabled
              control={
                <Checkbox
                  icon={<BookmarkBorderIcon />}
                  checkedIcon={<BookmarkIcon />}
                  label="Cleaning complete"
                  defaultChecked
                  sx={{
                    color: lightGreen[400],
                    "&.Mui-checked": {
                      color: lightGreen[400],
                    },
                    "&.MuiCheckbox-root": {
                      padding: "1px", // Checkboxdagi umumiy padding
                    },
                  }}
                />
              }
              label={`${t("Cleaning complete")} : ${cleanComplete}`}
              labelPlacement="end"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.88rem", textTransform: "uppercase", fontWeight: 500 } }}
            />
            <FormControlLabel
              value="end"
              disabled
              control={
                <Checkbox
                  icon={<BookmarkBorderIcon />}
                  checkedIcon={<BookmarkIcon />}
                  label="Maintains"
                  defaultChecked
                  sx={{
                    color: blue[800],
                    "&.Mui-checked": {
                      color: blue[600],
                    },
                    "&.MuiCheckbox-root": {
                      padding: "1px", // Checkboxdagi umumiy padding
                    },
                  }}
                />
              }
              label={`${t("In Maintains")} : ${maintains}`}
              labelPlacement="end"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.88rem", textTransform: "uppercase", fontWeight: 500 } }}
            />
          </Grid>
        </div>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          orientation={isSmallScreen ? "vertical" : "horizontal"}
          sx={{ minHeight: "45px", height: "45px", padding: 0 }}
        >
          <Tab
            icon={<StickyNote2Icon />}
            iconPosition="end"
            label={t(`Chess`)}
            {...a11yProps(0)}
            sx={{ minHeight: "45px", height: "45px" }}
          />
          <Tab
            icon={<FactCheckIcon />}
            iconPosition="end"
            label={t("Booking")}
            {...a11yProps(1)}
            sx={{ minHeight: "45px", height: "45px" }}
          />
          <Tab
            icon={<DesktopOutlined />}
            iconPosition="end"
            label={t("Check in")}
            {...a11yProps(2)}
            sx={{ minHeight: "45px", height: "45px" }}
          />
          <Tab
            icon={<TransferWithinAStationIcon />}
            iconPosition="end"
            label={t("Check out")}
            {...a11yProps(3)}
            sx={{ minHeight: "45px", height: "45px" }}
          />
          <Tab
            icon={<BedroomChildIcon />}
            iconPosition="end"
            label={t("Rooms")}
            {...a11yProps(4)}
            sx={{ minHeight: "45px", height: "45px" }}
          />
          <Tab
            icon={<PeopleIcon />}
            iconPosition="end"
            label={t("Guests")}
            {...a11yProps(5)}
            sx={{ minHeight: "45px", height: "45px" }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} isPartner={isPartner} handleOpenMainModal={handleOpenMainModal} setValue={setValue}>
        <Shaxmatka />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} isPartner={isPartner} handleOpenMainModal={handleOpenMainModal} setValue={setValue}>
        <BookingPage />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} isPartner={isPartner} handleOpenMainModal={handleOpenMainModal} setValue={setValue}>
        <CheckInPage />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3} isPartner={isPartner} handleOpenMainModal={handleOpenMainModal} setValue={setValue}>
        <CheckoutPage />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4} isPartner={isPartner} handleOpenMainModal={handleOpenMainModal} setValue={setValue}>
        <RoomsPage />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5} isPartner={isPartner} handleOpenMainModal={handleOpenMainModal} setValue={setValue}>
        <Guests />
      </CustomTabPanel>
    </Box>
  );
}
