import * as React from "react";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const defaultTheme = createTheme();

export default function SignInSide() {
    const [visibleIcon, setVisibleIcon] = React.useState(true);
    const [license, setLicense] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [loadingLogo, setLoadingLogo] = React.useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const uuid = sessionStorage.getItem("logo");
    const name = sessionStorage.getItem("name");
    const handleSubmit = async (event) => {
        setLoading(true);
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/hdp-gateway/login`,
                {
                    login: sessionStorage.getItem("login"),
                    password: event.password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                setLoading(false);
                sessionStorage.setItem("token", res?.data?.data);
                navigate("/");
                toast.success(t("Login successfully"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Login filled"));
            });
    };

    const getLogo = async () => {
        console.log(uuid)
        if (uuid.length) {
            setLoadingLogo(true);
            await axios(`${process.env.REACT_APP_API_URL}/hdp-gateway/hotel/login/logo/${uuid}`, {
                responseType: "blob",
                headers: { Authorization: sessionStorage.getItem("token") },
            })
                .then((res) => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        if (res.data.type === "application/pdf" || /image/.test(res?.data?.type)) {
                            window.document.getElementById("login_logo").src = `${event?.target?.result}`;
                        } else {
                            console.log("else");
                        }
                    };
                    reader.readAsDataURL(res?.data);
                    setLoadingLogo(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoadingLogo(false);
                });
        }
    };

    const validate = Yup.object({
        password: Yup.string().required("Password is required"),
    });

    React.useEffect(() => {
        if (sessionStorage.getItem("license") === "false") {
            setLicense(false);
        } else {
            setLicense(true);
        }
        getLogo();
    }, []);

    const handleMouseOver = (e) => {
        e.currentTarget.style.backgroundColor = '#e0e0e0';
    };

    const handleMouseOut = (e) => {
        e.currentTarget.style.backgroundColor = '';
    };

    const goBack = () => {
        sessionStorage.clear();
        navigate("/");
    };
    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: "90vh", display: "flex", justifyContent: "center" }}>
                {loadingLogo ? (
                    <Loading />
                ) : (
                    <Grid item xs={12} sm={8} md={5}>
                        {!license && (
                            <Alert severity="error" className="mt-3">
                                <AlertTitle>{t("Something went wrong. Please call:")} +998 99 895 90 90</AlertTitle>
                            </Alert>
                        )}
                        <Box
                            sx={{
                                marginTop: 20,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <div className="card">
                                <div className="card-body">
                                    {loading && <div className="overlay" />}
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center justify-content-center border rounded-circle"
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                transition: 'background-color 0.3s ease',
                                                cursor: 'pointer',
                                            }}
                                            onMouseOver={handleMouseOver}
                                            onMouseOut={handleMouseOut}
                                            onClick={goBack}
                                        >
                                            <ArrowBackIcon />
                                        </div>
                                        <Typography component="h1" variant="h5" className="Sign_title">
                                            {t("Log in step two")}
                                        </Typography>
                                        <div style={{
                                            width: '50px'
                                        }}></div>
                                    </div>
                                    <Formik
                                        initialValues={{ password: "" }}
                                        validationSchema={validate}
                                        onSubmit={async (values) => {
                                            handleSubmit(values);
                                            return new Promise((res) => setTimeout(res, 500));
                                        }}
                                    >
                                        <Form autoComplete="off">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className="d-flex justify-content-between align-items-center login_input">
                                                    <h5>{name}</h5>
                                                    <div>
                                                        <img id="login_logo" title="test" alt="Logo" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className="login_input btn_wrapper">
                                                <Field
                                                    fullWidth
                                                    size="small"
                                                    label={t("Password")}
                                                    name="password"
                                                    component={TextField}
                                                    autoComplete="password"
                                                    type={visibleIcon ? "password" : "text"}
                                                />
                                                {visibleIcon ? (
                                                    <VisibilityOffIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                                ) : (
                                                    <RemoveRedEyeIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                                )}
                                            </Grid>
                                            <Button
                                                loading
                                                type="submit"
                                                fullWidth
                                                disabled={loading || !license ? true : false}
                                                variant="contained"
                                                className="login_input"
                                            >
                                                {t("Continue")}
                                            </Button>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </ThemeProvider>
    );
}
