import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

export default function NotificationMessageModal({ setShow, show, message }) {
    const { t } = useTranslation();
    const [showtrue, setShowTrue] = useState(false);
    useEffect(() => setShowTrue(show), [show]);

    const [sideStyle, setSideStyle] = useState({});
    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "480px", top: "64px" });
    };
    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        if (e.currentTarget === e.target) hide();
    };
    const hide = () => {
        setShowTrue(false);
        setTimeout(() => setShow(false), 300);
    };

    useEffect(() => {
        if (message.status === 1) {
            const body = { id: message.id };
            const headers = { Authorization: `Bearer ${sessionStorage.getItem("token")}` };
            axios.put(`${process.env.REACT_APP_API_URL}/hdp-gateway/notification/status`, body, { headers }).catch((err) => console.log(err));
        }
    }, []);

    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-480px", backgroundColor: "rgba(240, 240, 240)" }}>
                <div className={styles.title}>
                    <span className={styles.close} onClick={hide}>
                        &larr;
                    </span>
                </div>
                <div className="message">
                    <h3 className="message-title">{message.title}</h3>
                    <p className="message-text">{message.message}</p>
                </div>
            </div>
        </div>
    );
}
