import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const baseregex = /^([\s]*)(?!.*<.*>)(?!.* or .*=).*([\s]*)$/i;
export const phoneregex = /^([+]?\d{1,3})\s?(\d{2,3}\s?){2,3}\d{0,3}$/;

function TransitionsModal({ handleOpenMainModal, isPartner, getData }) {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validate = Yup.object({
        contact_name: Yup.string()
            .required(t("Field is required"))
            .matches(baseregex, { message: t("Invalid value") }),
        email: Yup.string().optional().email(t("Invalid value")),
        phone_number: Yup.string().when("email", (email, schema) => {
            if (!email[0])
                return schema
                    .required(t("Must contain at least one of the following: phone number, email"))
                    .matches(phoneregex, { message: t("Invalid value") });
            else return schema.optional();
        }),
    });
    const emptyData = { contact_name: "", phone_number: "", email: "" };

    const onSubmit = async (values) => {
        const data = { contact_name: values.contact_name };
        if (values.phone_number) data.phone_number = values.phone_number;
        if (values.email) data.email = values.email;

        setLoading(true);
        await axios
            .post(`${process.env.REACT_APP_API_URL}/hdp-gateway/contact`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Saved successfully"));
                handleClose();
                getData();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
    };

    return (
        <div className="modal_res">
            <Button
                onClick={() => {
                    if (isPartner) {
                        handleOpenMainModal();
                        return "";
                    }
                    handleOpen();
                }}
                variant="contained"
                startIcon={<PersonIcon />}
            >
                {t("Add contact")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="modal_res">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter contact info")}
                        </Typography>
                        <Formik initialValues={emptyData} validationSchema={validate} onSubmit={onSubmit}>
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <Field fullWidth name="contact_name" size="small" component={TextField} label={t("Contact name")} type="text" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field fullWidth name="phone_number" size="small" component={TextField} label={t("Phone number")} type="text" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field fullWidth name="email" size="small" component={TextField} label={t("Email")} type="text" />
                                        </Grid>
                                    </Grid>
                                    <div className="right_left">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                            {t("Close")}
                                        </Button>
                                        <>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {t("Submit")}
                                                </LoadingButton>
                                            ) : (
                                                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {t("Submit")}
                                                </Button>
                                            )}
                                        </>
                                        {loading && <div className="overlay" />}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
