import { t } from "i18next";

const HoverCard = ({ visible, x, y, data }) => {
  if (!visible) return null;

  const titles = [
    { name: "Number of people", value: data.number_of_people },
    { name: "Price", value: data.price },
    { name: "Status", value: data.status_name || data.status }
  ];

  if (data.reservations && data.reservations.length > 0) {
    const guests = data.reservations.map(reservation =>
      `${reservation.first_name} ${reservation.last_name}`
    );
    titles.push({ name: "Guests", value: guests });
  }

  return (
    <div
      className="position-absolute p-3 bg-white text-dark rounded-lg border rounded-1"
      style={{
        position: 'absolute',
        top: y,
        left: x,
        zIndex: 10,
        transition: "opacity 0.2s",
        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"
      }}
    >
      {titles.map((title) => {
        return (
          <div style={{ width: "200px" }} className="mb-2">
            <div className="border-bottom border-2 mb-1 border-primary d-flex" style={{ borderColor: "#007bff" }}>
              <div style={{
                width: "max-content",
                backgroundColor: '#007bff',
                position: "relative"
              }} className="ps-1 pe-5">
                <span className="text-white text-nowrap">{t(title.name)}</span>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: 0,
                    height: 0,
                    borderLeft: '13px solid transparent',
                    borderTop: '25px solid white',
                  }}
                ></div>
              </div>
            </div>

            {typeof title.value === "object" ?
              (
                <ul style={{ paddingLeft: 25 }}>
                  {title.value.map((item) =>
                    <li>{item}<br /></li>
                  )}
                </ul>
              ) : <span>{title.value}</span>}

          </div>
        )
      })}
    </div >
  );
};

export default HoverCard;