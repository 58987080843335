import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function SetEmployeeId({ token, setStep }) {
  const {t} = useTranslation()
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [empId, setEmpId] = useState();
  const [inval, setinval] = useState(empId);
  const [check, setcheck] = useState(false);

  useEffect(() => {
    if (empId !== undefined) {
      checkEID();
    }
  }, [empId]);

  async function checkEID() {
    setSubmitting(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/hdp-gateway/fth/generate-id/check?generate_id=${empId}`,
        { headers: { Authorization: token } }
      )
      .then((res) => {
        setcheck(res.data.data);
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  }
  function submit() {
    setSubmitting(true);
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/hdp-gateway/fth/generate-id`,
        { generate_id: empId },
        { headers: { Authorization: token } }
      )
      .then(() => {
        setSubmitting(false);
        setStep("logo");
        setLoading(false);
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  }
  return (
    <>
      <div className="d-flex justify-content-center flex-column container py-3">
        <div className="py-2">
          <div className="card">
            <div className="card-header">
              <h5 className="text-center">Generate ID</h5>
              {loading && <div className="overlay" />}
            </div>

            <div>
              <Card>
                <CardContent style={{ padding: "24px" }}>
                  {/* <CompanyInfo info={companyInfo} /> */}
                  <div>
                    <div
                      className="eidg-container"
                      onClick={() => {
                        document.getElementById("eidg").focus();
                      }}
                    >
                      <input
                        className="form-control"
                        maxLength="2"
                        id="eidg"
                        value={inval}
                        onChange={(e) => {
                          setcheck(false);
                          if (/^[a-z]{0,}$/.test(e.target.value)) {
                            setinval(e.target.value);
                            if (e.target.value.length === 2)
                              setEmpId(e.target.value);
                          }
                        }}
                      />
                      <div>
                        <h3>Your id: {empId}0000</h3>
                        {!/^[a-z]{2,2}$/.test(inval) ? (
                          <span style={{ color: "red", fontSize: "20px" }}>
                            enter two lowercase letters
                          </span>
                        ) : submitting ? (
                          <span
                            style={{
                              color: "powderblue",
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                            }}
                          >
                            please wait <CircularProgress />
                          </span>
                        ) : !check ? (
                          <span style={{ color: "red", fontSize: "20px" }}>
                            such employee id is already used
                          </span>
                        ) : (
                          <span style={{ color: "green", fontSize: "20px" }}>
                            can be used
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="l-page">
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        className="float-end mb-3"
                        disabled={!check || loading}
                        onClick={submit}
                      >
                        {loading && <CircularProgress size="0.9rem" />}
                       Next
                      </Button>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
