import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PersonIcon from '@mui/icons-material/Person';
import LogsTable from './LogsTable';
import UserRolesTable from './UserRoles';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(2);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onBack = () => {
    sessionStorage.removeItem("first_name");
    sessionStorage.removeItem("last_name");
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab
        onClick={onBack}
                    component={Link}
                    label={
                        <div>
                            <ArrowBackIosNewIcon fontSize="small" /> {t("Back")}
                        </div>
                    }
                    to="/admin-users"
                    {...a11yProps(0)}
                />
                <Tab
                    label={
                        <div className="hotel-name-on-tab">
                            <PersonIcon fontSize="small" /> {sessionStorage.getItem("first_name")} {sessionStorage.getItem("last_name")}
                        </div>
                    }
                    disabled
                    {...a11yProps(1)}
                />
          <Tab label={t("Work time")} {...a11yProps(2)} />
          <Tab label={t("Roles")} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={2}>
        <LogsTable/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <UserRolesTable/>
      </CustomTabPanel>
    </Box>
  );
}