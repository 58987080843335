import React, { useEffect, useState } from "react";
import axios from "axios";
import { Layout, theme } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, Routes, Route, Navigate, useSearchParams, useNavigate } from "react-router-dom";
import Headers from "./components/Layout/Navbar";
import Main from "./components/Layout/Content";
import Side from "./components/Layout/Sidebar";
import Footer from "./components/Layout/Footer";
import Backdrop from "@mui/material/Backdrop";

import "./App.css";
import { CheckInProvider } from "./components/Context/CheckInContext";
import { GetProvider } from "./components/Context/GetRolesContext";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import HotelActivePage from "./pages/HotelActivePage";
import ProtectadPage from "./components/ProtectedPage";
import { Box, Button, Fade, Modal, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import FTUPage from "./pages/FTU";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const App = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [name, setName] = useState(null);
    const [first_name, setFirst_name] = useState(null);
    const [last_name, setLast_name] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [uuid, setUuid] = useState(null);
    const auth = sessionStorage.getItem("token");
    const [collapsed, setCollapsed] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isPartner, setIsPartner] = React.useState(true);
    const handleOpenMainModal = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const notallowedPaths = ["/monthly-report", "/role-assignment", "/housekeeping-assignment", "/housekeeping-pdf", "/housekeepers-rooms"];
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [selectedKeys, setSelectedKeys] = useState("/");
    const location = useLocation();
    const navigate = useNavigate();

    const getData = async () => {
        if (!sessionStorage.getItem("token")) return;
        await axios
            .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/hotel/self`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setName(res.data.data.name);
                setUuid(res.data.data.logo);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getMe = async () => {
        if (!sessionStorage.getItem("token")) return;
        await axios
            .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/user/me`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                const arr = res.data.data.roles;
                setFirst_name(res.data.data.first_name);
                setLast_name(res.data.data.last_name);
                const isPartner = sessionStorage.getItem("partner") === "partner";
                setIsPartner(isPartner);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getmemberstatus = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/hotel/changestatus`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Your request has been sent and we will contact you soon"));
                handleClose();
                getData();
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                toast.error(t("you have already sent a request"));
            });
    };

    const til = localStorage.getItem("i18nextLng");
    if (til == "en" || til == "uz" || til == "ru") {
    } else {
        localStorage.setItem("i18nextLng", "en");
    }
    useEffect(() => {
        getData();
        getMe();
        const pathName = location.pathname;
        if (isPartner && notallowedPaths.includes(pathName)) {
            handleOpenMainModal();
            navigate("/");
        }
        setSelectedKeys(pathName);
    }, [location.pathname]);

    const active = searchParams.get("hotel");
    const ftu = searchParams.get("ftu") || sessionStorage.getItem("ftu");
    return (
        <div style={{ overflow: "hidden" }}>
            <div className="modal_res">
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style} className="modal_res">
                            <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                                {t("These features are for members only")}
                            </Typography>
                            <Typography variant="body2" color="text">
                                {t("Would you like to upgrade your status to Member ?")}
                            </Typography>
                            <div className="right_left">
                                <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                    {t("Close")}
                                </Button>
                                <>
                                    {loading ? (
                                        <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                            {t("Submit")}
                                        </LoadingButton>
                                    ) : (
                                        <Button
                                            onClick={() => {
                                                getmemberstatus();
                                            }}
                                            type="submit"
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            color="success"
                                        >
                                            {t("Submit")}
                                        </Button>
                                    )}
                                </>
                                {loading && <div className="overlay" />}
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>
            <ToastContainer />
            {auth ? (
                <GetProvider>
                    <CheckInProvider>
                        <Layout>
                            <Side uuid={uuid} handleOpenMainModal={handleOpenMainModal} collapsed={collapsed} selectedKeys={selectedKeys} />
                            <Layout>
                                <Headers first_name={first_name} last_name={last_name} name={name} collapsed={collapsed} setCollapsed={setCollapsed} />
                                <Main handleOpenMainModal={handleOpenMainModal} isPartner={isPartner} bg={colorBgContainer} />
                                <Footer />
                            </Layout>
                        </Layout>
                    </CheckInProvider>
                </GetProvider>
            ) : (
                <>
                    <Routes>
                        <Route path="/" element={<Navigate to="/login" />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/hotel-active" element={active ? <HotelActivePage /> : <ProtectadPage />} />
                        <Route path="/ftu" element={ftu ? <FTUPage /> : <ProtectadPage />} />
                        <Route path="/*" element={<Navigate to="/login" />} />
                    </Routes>
                </>
            )}
        </div>
    );
};

export default App;
